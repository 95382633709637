import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import FontSizeChanger from "react-font-size-changer";
import MaterialIcon from "material-icons-react";
import FontAwesome from "react-fontawesome";
import "../index.css";
import { BaseUrl } from "../BasePath";
import { StyleSheet, css } from "aphrodite";
import { useState, useEffect } from "react";
import "../css/Navbar.css";
import SearchBar from "./SearchBar";

const navBarStyle = StyleSheet.create({
  navBar: {
    display: "flex",
    direction: "row",
    margin: "auto",
    // "@media (max-width: 991.5px)": {
    //   "flex-direction": "column",
    // },
  },
});

const Navbar = (props) => {
  // Scroll check for navbar
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    console.log(scrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(props);

  function compare(a, b) {
    if (a.priority < b.priority) {
      return -1;
    }
    if (a.priority > b.priority) {
      return 1;
    }
    return 0;
  }
  // console.log("hey", navbarSampleData);
  const isReady = props.isReady;
  const navbarData = props.navbarData;
  // const history = useHistory();
  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>

<div className="container-fluid  d-none d-sm-block" style={{height:'47px',paddingTop:'5px',background:"#2b4675"}}>
<div className="row" style={{margin:"0% 2.5%"}}>

<div className="col-md-6">
<p style={{color:"#fff",fontWeight:"bold"}}>
<span class="text_1">Welcome to National Institute of Technology Warangal</span>
{/* <span class="text_2">Welcome to National Institute of Technology Warangal</span> */}
{/* <span class="text_2">National Institute of Technology Warangal is ranked 21st in Engineering Category in NIRF-2024</span> */}
</p>
</div>
<div className="col-md-6">
<div className="rd-navbar-top-panel-center-part" >
  
                       
                        <div
                          style={{
                            // display: "flex",
                            direction: "row",
                            margin: "auto",
                            float:"right"
                          }}
                          className={css(navBarStyle.navBar)}
                        >
                          {/* <div className="unit flex-row align-items-center unit-spacing-xs">
                            <div className="unit-left">
                              <span className="icon mdi mdi-phone text-middle" />
                            </div>
                            <div className="unit-body">
                              <a href="tel:0820-2459366">0870-2459366</a>
                            </div>
                          </div> */}
                          {/* <div className="unit flex-row align-items-center unit-spacing-xs">
                            <div className="unit-left">
                              <span className="icon mdi mdi-map-marker text-middle" />
                            </div>
                            <div className="unit-body">
                              <a href="tel:+91-870-2459191">
                                NH 163, NITW Campus, Telangana State-506004, India
                              </a>
                            </div>
                          </div> */}
                          {/* <div className="unit flex-row align-items-center unit-spacing-xs">
                            <div className="unit-left">
                              <span className="icon mdi mdi-email-open text-middle" />
                            </div>
                            <div className="unit-body">
                              <a href="mailto:webmaster@nitw.ac.in">webmaster@nitw.ac.in</a>
                            </div>
                          </div> */}
                          
                          
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            className="unit flex-row  unit-spacing-xs"
                          >
                            <a
                              href="https://www.facebook.com/NITWarangalOfficial/"
                              target="_blank"
                              style={{ opacity: 80 }}
                              className="icon icon-xxs fa fa-facebook icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80 }}
                              href="https://x.com/warangal_nit"
                              className="icon icon-xxs fa fa-twitter icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80, color: "red" }}

                              href="https://www.youtube.com/@wsdcnitwarangal1954"
                              target="_blank"
                              className="icon icon-xxs fa fa-youtube-play icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80 }}
                              href="https://www.linkedin.com/school/national-institute-of-technology-warangal/posts/?feedView=all"
                              className="icon icon-xxs fa fa-linkedin icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80 }}
                              href="https://www.instagram.com/nitwarangalofficial/"
                              target="_blank"
                              className="icon icon-xxs fa fa-instagram icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <FontSizeChanger
                              targets={[".font-change-applicable"]}
                              options={{
                                stepSize: 2,
                                range: 6,
                              }}
                              customButtons={{
                                up: <FontAwesome name="search-plus" />,
                                down: <FontAwesome name="search-minus" />,
                                style: {
                                  color: "#fff",
                                  WebkitBoxSizing: "border-box",
                                  WebkitBorderRadius: "2rem",
                                  borderWidth: "0.15rem",
                                  margin: "auto",
                                },
                                buttonsMargin: 10,
                              }}
                            />
                            <div
                              style={{
                                border: "solid 0.15rem",
                                padding: "0.25rem 0.6rem",
                                borderRadius: "2rem",
                                borderColor:"#fff"
                              }}
                            >
                              <Link
                                href={`${window.location.href}`}
                                onClick={() => window.location.reload()}
                                style={{color:"#fff"}}
                              >
                                Reset
                              </Link>
                            </div>
                            {/* <div style={{
                                marginLeft:" auto",
                                border: "solid 0.15rem",
                                padding: "0.25rem 0.6rem",
                                borderRadius: "0.8rem",
                              }}>
                            <a
                                href={'http://oldweb.nitw.ac.in/'}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Old Website
                              </a>
                            </div> */}
                          </div>
                        </div>
                        {/* 
                         <ul className="list-unstyled">
                          <li>
                            <div className="unit flex-row align-items-center unit-spacing-xs">
                              <div className="unit-left">
                                <span className="icon mdi mdi-phone text-middle" />
                              </div>
                              <div className="unit-body">
                                <a href="tel:9121096946">9121096946</a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="unit flex-row align-items-center unit-spacing-xs">
                              <div className="unit-left">
                                <span className="icon mdi mdi-map-marker text-middle" />
                              </div>
                              <div className="unit-body">
                                <a href="/">
                                  NH 163, NITW Campus, Telangana 506004 India
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="unit flex-row align-items-center unit-spacing-xs">
                              <div className="unit-left">
                                <span className="icon mdi mdi-email-open text-middle" />
                              </div>
                              <div className="unit-body">
                                <a href="mailto:#">example-email@nitw.ac.in</a>
                              </div>
                            </div>
                          </li>

                          <li>
                            <FontSizeChanger
                              targets={[".font-change-applicable"]}
                              options={{
                                stepSize: 2,
                                range: 6,
                              }}
                              customButtons={{
                                up: <FontAwesome name="search-plus" />,
                                down: <FontAwesome name="search-minus" />,
                                style: {
                                  color: "white",
                                  WebkitBoxSizing: "border-box",
                                  WebkitBorderRadius: "5px",
                                  margin: "auto",
                                },
                                buttonsMargin: 10,
                              }}
                            />
                          </li>
                          <li>
                            <Link
                              href="/"
                              className="top-btn"
                              onClick={() => window.location.reload()}
                            >
                              Reset
                            </Link>
                          </li>
                        </ul>  */}
 </div>
</div>

</div>


                      

</div>



      <header className={"page-head"}>
        <div className="rd-navbar-wrap">
          <nav
            className="rd-navbar rd-navbar-default"
            data-md-device-layout="rd-navbar-static"
            data-lg-device-layout="rd-navbar-static"
            data-xl-device-layout="rd-navbar-static"
            data-stick-up-offset="210"
            data-xl-stick-up-offset="85"
            data-lg-auto-height="true"
            data-auto-height="false"
            data-md-layout="rd-navbar-static"
            data-lg-layout="rd-navbar-static"
            data-lg-stick-up="true"
            // style={{
            //   backgroundColor: "#E1D29A",
            //   borderBottom: "7px solid maroon",
            // }}
            style={{
              // backgroundImage: "url(https://nitw.ac.in/api/static/files/ribbon_light_strip_brown_2023-6-15-11-41-47.jpg)",
              // backgroundImage: "url(https://nitw.ac.in/api/static/files/bg3_2024-2-29-18-33-32.jpg)",
              // backgroundImage:
              //   "url(https://nitw.ac.in/api/static/files/bg1_2024-5-18-17-36-11.jpg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderBottom: "7px solid #2b4675",
            }}
          >
            <div className="rd-navbar-inner">
              {/* Navbar Panel Top*/}
              <div>
                <div className="rd-navbar-panel">
                  <div
                    style={{
                      // display:"flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Navbar Sidebar Toggle*/}
                    <button
                      className="rd-navbar-toggle"
                      data-rd-navbar-toggle=".rd-navbar, .rd-navbar-nav-wrap"
                      style={{ float: "left", position: "relative" }}
                    >
                      <span />
                    </button>
                    {/* Mobile Page Title */}
                    <img
                      className="panel-title d-lg-none"
                      width={315}
                      height="auto"
                      // src={process.env.PUBLIC_URL + "/images/nit-logo-jpeg.png"}
                      src={
                        process.env.PUBLIC_URL + "/images/NITW_logo_telugu3.png"
                      }
                      alt={"nitw brand logo"}
                    />

                    {/* <h2 className="panel-title d-lg-none">NIT Warangal</h2> */}

                    {/* Navbar Right Side Toggle*/}
                   
                    <button
                      className="rd-navbar-top-panel-toggle d-lg-none"
                      data-rd-navbar-toggle=".rd-navbar-top-panel"
                    >
                      <span />
                    </button>

                    
                    {/* Alternate navbar */}
                    <div
                      className="rd-navbar-top-panel d-md-none"
                      style={{ backgroundColor: "#0cfcfd", color:"black", float: "right", position: "relative"}}
                      // style={{ backgroundColor: "#45110e" }}
                    >



                      
                    <div className="rd-navbar-top-panel-center-part" >
                        <div
                          style={{
                            // display: "flex",
                            direction: "row",
                            margin: "auto",
                            float:"right"
                          }}
                          className={css(navBarStyle.navBar)}
                        >
                          {/* <div className="unit flex-row align-items-center unit-spacing-xs">
                            <div className="unit-left">
                              <span className="icon mdi mdi-phone text-middle" />
                            </div>
                            <div className="unit-body">
                              <a href="tel:0820-2459366">0870-2459366</a>
                            </div>
                          </div> */}
                          {/* <div className="unit flex-row align-items-center unit-spacing-xs">
                            <div className="unit-left">
                              <span className="icon mdi mdi-map-marker text-middle" />
                            </div>
                            <div className="unit-body">
                              <a href="tel:+91-870-2459191">
                                NH 163, NITW Campus, Telangana State-506004, India
                              </a>
                            </div>
                          </div> */}
                          {/* <div className="unit flex-row align-items-center unit-spacing-xs">
                            <div className="unit-left">
                              <span className="icon mdi mdi-email-open text-middle" />
                            </div>
                            <div className="unit-body">
                              <a href="mailto:webmaster@nitw.ac.in">webmaster@nitw.ac.in</a>
                            </div>
                          </div> */}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            className="unit flex-row  unit-spacing-xs"
                          >
                            <a
                              href="https://www.facebook.com/NITWarangalOfficial/"
                              target="_blank"
                              style={{ opacity: 80 }}
                              className="icon icon-xxs fa fa-facebook icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80 }}
                              href="https://x.com/warangal_nit"
                              className="icon icon-xxs fa fa-twitter icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80, color: "red" }}

                              href="https://www.youtube.com/@wsdcnitwarangal1954"
                              target="_blank"
                              className="icon icon-xxs fa fa-youtube-play icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80 }}
                              href="https://www.linkedin.com/school/national-institute-of-technology-warangal/posts/?feedView=all"
                              className="icon icon-xxs fa fa-linkedin icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <a
                              style={{ opacity: 80 }}
                              href="https://www.instagram.com/nitwarangalofficial/"
                              target="_blank"
                              className="icon icon-xxs fa fa-instagram icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                            <FontSizeChanger
                              targets={[".font-change-applicable"]}
                              options={{
                                stepSize: 2,
                                range: 6,
                              }}
                              customButtons={{
                                up: <FontAwesome name="search-plus" />,
                                down: <FontAwesome name="search-minus" />,
                                style: {
                                  color: "black",
                                  WebkitBoxSizing: "border-box",
                                  WebkitBorderRadius: "2rem",
                                  borderWidth: "0.15rem",
                                  margin: "auto",
                                },
                                buttonsMargin: 10,
                              }}
                            />
                            <div
                              style={{
                                border: "solid 0.15rem",
                                padding: "0.25rem 0.6rem",
                                borderRadius: "2rem",
                              }}
                            >
                              <Link
                                href={`${window.location.href}`}
                                onClick={() => window.location.reload()}
                              >
                                Reset
                              </Link>
                            </div>
                            {/* <div style={{
                                marginLeft:" auto",
                                border: "solid 0.15rem",
                                padding: "0.25rem 0.6rem",
                                borderRadius: "0.8rem",
                              }}>
                            <a
                                href={'http://oldweb.nitw.ac.in/'}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Old Website
                              </a>
                            </div> */}
                          </div>
                        </div>
                        {/* 
                         <ul className="list-unstyled">
                          <li>
                            <div className="unit flex-row align-items-center unit-spacing-xs">
                              <div className="unit-left">
                                <span className="icon mdi mdi-phone text-middle" />
                              </div>
                              <div className="unit-body">
                                <a href="tel:9121096946">9121096946</a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="unit flex-row align-items-center unit-spacing-xs">
                              <div className="unit-left">
                                <span className="icon mdi mdi-map-marker text-middle" />
                              </div>
                              <div className="unit-body">
                                <a href="/">
                                  NH 163, NITW Campus, Telangana 506004 India
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="unit flex-row align-items-center unit-spacing-xs">
                              <div className="unit-left">
                                <span className="icon mdi mdi-email-open text-middle" />
                              </div>
                              <div className="unit-body">
                                <a href="mailto:#">example-email@nitw.ac.in</a>
                              </div>
                            </div>
                          </li>

                          <li>
                            <FontSizeChanger
                              targets={[".font-change-applicable"]}
                              options={{
                                stepSize: 2,
                                range: 6,
                              }}
                              customButtons={{
                                up: <FontAwesome name="search-plus" />,
                                down: <FontAwesome name="search-minus" />,
                                style: {
                                  color: "white",
                                  WebkitBoxSizing: "border-box",
                                  WebkitBorderRadius: "5px",
                                  margin: "auto",
                                },
                                buttonsMargin: 10,
                              }}
                            />
                          </li>
                          <li>
                            <Link
                              href="/"
                              className="top-btn"
                              onClick={() => window.location.reload()}
                            >
                              Reset
                            </Link>
                          </li>
                        </ul>  */}
                      </div>
                     


                    </div> 









                  </div>
                </div>
              </div>
              {/* Navbar Starts Here*/}
              <div className="rd-navbar-menu-wrap clearfix font-change-applicable">
                {/* Navbar Brand Logo*/}
                <div className="rd-navbar-brand searchLocation">
                  <Link
                    className="d-inline-block"
                    to="/"
                    onClick={() => (window.location.href = "/")}
                  >
                    <div className="unit align-items-sm-center unit-xl flex-column flex-xxl-row unit-spacing-custom">
                      <div className="unit-left">
                        <img
                          className="Headeimage"
                          width={750}
                          height={172}
                          src={
                            process.env.PUBLIC_URL +
                            "/images/NITW_logo_telugu3.png"
                          }
                          // src={
                          //   process.env.PUBLIC_URL + "/images/nit-logo-jpeg.png"
                          // }
                          alt={"nitw brand logo"}
                        />

                        {/* <img
                          width={170}
                          height={172}
                          src={
                            process.env.PUBLIC_URL + "/images/logo-170x172.png"
                          }
                          alt={"nitw brand logo"}
                        /> */}
                      </div>
                      {/* <div className="unit-body text-xxl-start">
                        <div className="rd-navbar-brand-title">
                          <h3><b>National Institute of Technology Warangal</b></h3>
                          </div>
                        <div className="rd-navbar-brand-slogan">
                        <h5>An Institute of National Imortance </h5>
                        </div>
                      </div> */}
                    </div>
                  </Link>
                  <SearchBar />
                </div>

                <div
                  className={
                    !window.scrollY
                      ? "rd-navbar-nav-wrap"
                      : "rd-navbar-nav-wrap rd-navbar-scroll-align"
                  }
                >
                  <div className="rd-navbar-mobile-scroll">
                    <div className="rd-navbar-mobile-header-wrap">
                      {/*Navbar Brand Mobile*/}
                      <div className="rd-navbar-mobile-brand">
                        <Link to="/">
                          <img
                            width={136}
                            height={138}
                            src={
                              process.env.PUBLIC_URL +
                              "/images/logo-170x172.png"
                            }
                            alt={"nitw logo mobile"}
                          />
                        </Link>
                      </div>
                    </div>
                    {/* RD Navbar Nav*/}
                    {!isReady ? (
                      ""
                    ) : (
                      <ul className="rd-navbar-nav ">
                        <li
                          className="headNavitem"
                          style={{ color: "#f16127" }}
                        >
                          <div className="">
                            <Link
                              onClick={() => {
                                window.location.href = `${BaseUrl}/`;
                              }}
                            >
                              <MaterialIcon icon="home" color="#2b4675" />
                            </Link>
                          </div>
                        </li>

                        {navbarData.map((navItem) => {
                          // console.log(navItem);
                          return (
                            <li className="headNavitem">
                              {navItem.is_internal === true && (
                                <Link>{navItem.title}</Link>
                              )}
                              {navItem.is_internal === false && (
                                <a href={navItem.href}>{navItem.title}</a>
                              )}
                              {navItem.dropdowns.length > 0 &&
                                navItem.dropdowns.sort(compare) && (
                                  <ul
                                    className={"rd-navbar-dropdown text-dark"}
                                    style={{
                                      // backgroundColor: "#c5f2e0",
                                    //   backgroundColor: "#0cfcfd",
                                      backgroundColor: "#fff",
                                      // backgroundColor: "rgb(225, 210, 154)",
                                    }}
                                  >
                                    {navItem.dropdowns.map((dropdownItem) => {
                                      if (dropdownItem.is_internal)
                                        return (
                                          <li className="Navitem">
                                            <Link
                                              to={
                                                dropdownItem.is_path
                                                  ? {
                                                    pathname: `/path/?dept=${dropdownItem.route}`,
                                                  }
                                                  : {
                                                    pathname: `/page/?url=${dropdownItem.route}`,
                                                  }
                                              }
                                              onClick={() => {
                                                dropdownItem.is_path
                                                  ? (window.location.href = `${BaseUrl}/path/?dept=${dropdownItem.route}`)
                                                  : (window.location.href = `${BaseUrl}/page/?url=${dropdownItem.route}`);
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "black",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {" "}
                                                {dropdownItem.title}
                                              </span>
                                            </Link>
                                          </li>
                                        );
                                      else
                                        return (
                                          <li className="Navitem">
                                            {/* <a href="https://web.whatsapp.com/">
                                            {dropdownItem.title}
                                          </a> */}
                                            <Link
                                              to={{
                                                pathname: `${dropdownItem.route}`,
                                              }}
                                              onClick={() =>
                                                (window.location.href = `${dropdownItem.route}`)
                                              }
                                            >
                                              <span
                                                style={{
                                                  color: "black",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {" "}
                                                {dropdownItem.title}{" "}
                                              </span>
                                            </Link>
                                          </li>
                                        );
                                    })}
                                  </ul>
                                )}
                            </li>
                          );
                        })}
                        <li className="headNavitem">
                          <div className="navbar-gov-logo rd-navbar--has-dropdown rd-navbar-submenu">
                            <Link
                              target="_blank"
                              to={{ pathname: "https://www.g20.org/en/ " }}
                            >
                              <img
                                width="100"
                                src={
                                  "https://amritmahotsav.nic.in/writereaddata/portal/images/g-20-logo.png"
                                }
                              ></img>
                            </Link>
                          </div>
                        </li>
                        <li className="headNavitem rd-navbar--has-dropdown rd-navbar-submenu navbar-gov-logo">
                          <div className="">
                            <Link
                              target="_blank"
                              to={{
                                pathname: "https://amritmahotsav.nic.in/ ",
                              }}
                            >
                              <img
                                width="100"
                                src={
                                  "https://amritmahotsav.nic.in/writereaddata/portal/images/logo-black.png"
                                }
                              ></img>
                            </Link>
                          </div>
                        </li>

                        {/* <li  >    </li> */}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Navbar;
