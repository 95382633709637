import React from "react";
import { getDepartmentData } from "../constants/DepartmentData";
import { Helmet } from "react-helmet";
import { MainServerUrl } from "../BasePath";

const PageHeader = (props) => {
  const title = getDepartmentData(props.dept);
  // console.log(props);
  // let bgurl = "https://oldweb.nitw.ac.in/static/main/images/defaultnitw2.jpg";
  let bgurl = `${MainServerUrl}/media/files/defaultnitw2.jpg_2023-7-4-18-41-25.jpg`;
  if (props.dept === 'Student Developers Team')
    bgurl = 'https://s3.amazonaws.com/hr-assets/0/1660526077-a5ecd11713-NITW.jpeg'


  const getbgURL = (dept) => {
    if (dept === "/mathematics")
      bgurl = `${MainServerUrl}/media/files/math_2022-0-22-21-36-19.jpeg`;
    if (dept === "/cse")
      bgurl = `${MainServerUrl}/media/files/CSE_Department_2021-10-14-10-2-30.jpg`;
    // if (dept === "/cs")
    //   bgurl = `${MainServerUrl}/media/files/cse_2022-0-22-20-13-32.jpg`;
    if (dept === "/cs")
      bgurl = `${MainServerUrl}/api/static/files/CSE_2023-5-27-19-9-59.jpg`;
    if (dept === "/eee")
      bgurl = `${MainServerUrl}/media/files/electrical_2022-0-22-21-15-3.jpeg`;
    if (dept === "/ece")
      bgurl = `${MainServerUrl}/media/files/ece_2022-0-22-21-19-45.jpeg`;
    if (dept === "/che")
      bgurl = `${MainServerUrl}/media/files/chemical_2022-0-18-15-43-18.jpg`;
    if (dept === "/cy")
      bgurl = `${MainServerUrl}/media/files/mme_2022-0-18-15-38-14.jpg`;
    if (dept === "/ce")
      bgurl = `${MainServerUrl}/media/files/civil_2022-0-22-21-30-32.jpeg`;
    if (dept === "/bt")
      bgurl = `${MainServerUrl}/media/files/chemical_2022-0-18-15-43-18.jpg`;
    if (dept === "/me")
      bgurl = `${MainServerUrl}/media/files/mech_2022-0-22-21-39-14.jpeg`;
    if (dept === "/pe")
      bgurl = `${MainServerUrl}/media/files/physical_dept_2021-11-24-9-56-58.jpg`;
    if (dept === "/physics")
      bgurl = `${MainServerUrl}/media/files/physics_2022-0-22-19-25-41.jpg`;
    if (dept === "/sm")
      bgurl = `${MainServerUrl}/media/files/sm_2022-0-22-21-42-52.jpeg`;
    if (dept === "/ihc")
      bgurl = `${MainServerUrl}/media/files/ihc_2022-0-22-21-49-45.jpeg`;
    if (dept === "/hss")
      bgurl = `${MainServerUrl}/media/files/hss_2022-0-22-21-23-27.jpeg`;
    if (dept === "/aa")
      bgurl = `${MainServerUrl}/media/files/academic_2022-0-22-22-7-33.jpeg`;
    // if (dept === "/ao")
    //   bgurl = `${MainServerUrl}/media/files/ao_2022-0-22-22-9-42.jpeg`;
    if (dept === "/placement")
      bgurl = `${MainServerUrl}/api/static/files/incubation_2022-7-14-14-50-19.jpg`;
    if (dept === "/AcademicCalender")
      bgurl = `${MainServerUrl}/media/files/calender_2022-0-22-22-20-33.jpeg`;
    if (dept === "/ad2021")
      bgurl = `${MainServerUrl}/media/files/placement_2022-0-22-21-52-9.jpeg`;
    if (dept === "/sc")
      bgurl = `${MainServerUrl}/media/files/sc_2022-0-18-22-6-37.jpg`;
    if (dept === "/eb")
      // bgurl = `${MainServerUrl}/api/static/files/Examination_Section_v1_2023-5-24-12-45-58.jpg`;
      // bgurl = `${MainServerUrl}/api/static/files/Examination_banner_2023-11-20-11-1-6.jpg`;
      bgurl = `${MainServerUrl}/api/static/files/Examination_banner_2023-11-24-17-53-28.jpg`;
    // if (dept === "/eb")
    //   bgurl = `${MainServerUrl}/media/files/eb_2022-0-22-22-28-40.jpeg`;
    if (dept === "/sw")
      bgurl = `${MainServerUrl}/media/files/sw_2022-0-18-21-56-58.jpg`;
    // if (dept === "/ao")
    //   bgurl = `${MainServerUrl}/api/static/files/Officers_banner_2023-11-21-12-17-3.jpg`;
    if (dept === "/ao")
      bgurl = `${MainServerUrl}/api/static/files/Officers_banner_2024-4-17-11-17-3.jpg`;
    if (dept === "/hostel")
      bgurl = `${MainServerUrl}/media/files/hostel_2022-0-18-21-41-11.jpg`;
    if (dept === "/centres")
      bgurl = `${MainServerUrl}/media/files/centre_2022-0-18-21-38-59.jpg`;
    if (dept === "/mme")
      bgurl = `${MainServerUrl}/media/files/mme_2022-0-18-15-38-14.jpg`;
    if (dept === "/nitwForms")
      bgurl = `${MainServerUrl}/media/files/forms_2022-0-22-22-13-42.jpeg`;
    if (dept === "/facilities/library")
      bgurl = `${MainServerUrl}/media/files/library_2021-10-13-22-40-15.jpg`;
    if (dept === "/siemens")
      bgurl = `${MainServerUrl}/api/static/files/siemens_2022-7-14-21-35-3.jpg`;
    // if (dept === "/DeanAcademic")
    //   bgurl = `${MainServerUrl}/media/files/Dean_Academic_2023-7-11-11-40-53.jpg`;
    if (dept === "/opportunities")
      bgurl = `${MainServerUrl}/api/static/files/Job_Opportunities_2023-8-11-18-22-51.jpg`;
    // if (dept === "/convocation2023")
    //   bgurl = `${MainServerUrl}/api/static/files/convocation_2023_h1_2023-7-22-20-5-16.jpg`;
    // if (dept === "/convocation2023")
    // bgurl = `${MainServerUrl}/api/static/files/Convocation2023_2023-8-13-12-16-4.jpg`;
    // if (dept === "/DeanRandC")
    //   bgurl = `${MainServerUrl}/api/static/files/sric_2023-8-11-13-8-53.jpg`;
    // if (dept === "/deansw")
    //   bgurl = `${MainServerUrl}/api/static/files/deansw_2023-10-13-12-21-49.jpg`;
    // if (dept === "/deansw")
    //   bgurl = `${MainServerUrl}/api/static/files/Dean_student_welfare_banner_2024-3-12-12-40-25.jpg`;

    // if (dept === "/registration")
    // bgurl = `${MainServerUrl}/api/static/files/convocation_registration_2023-7-29-16-13-22.jpg`;
    if (dept === "/registration")
      bgurl = `${MainServerUrl}/api/static/files/convocation_new_2023-8-12-19-37-33.jpg`;
    // if (dept === "/DeanAcademic")
    //   bgurl = `${MainServerUrl}/api/static/files/Academic_Cell_2023-8-9-12-24-54.jpg`;
    if (dept === "/DeanAcademic")
      bgurl = `${MainServerUrl}/api/static/files/DeanAcademic_banner_2024-4-17-11-21-19.jpg`;
    if (dept === "/EB")
      bgurl = `${MainServerUrl}/api/static/files/Examination_Section_banner_2024-4-17-11-23-3.jpg`;

    if (dept === "/AC")
      bgurl = `${MainServerUrl}/api/static/files/Academic_Calender_banner_2024-4-17-11-33-29.jpg`;


    if (dept === "/TT")
      bgurl = `${MainServerUrl}/api/static/files/Time_Tables_banner_2024-4-17-11-26-2.jpg`;

    if (dept === "/RAC")
      bgurl = `${MainServerUrl}/api/static/files/RAC_banner_2024-4-17-11-27-32.jpg`;

    if (dept === "/DeanRandC")
      bgurl = `${MainServerUrl}/api/static/files/SRIC_banner_2024-4-17-11-29-28.jpg`;


    if (dept === "/deansw")
      bgurl = `${MainServerUrl}/api/static/files/Dean_SW_banner_2024-4-17-11-30-23.jpg`;


    // if (dept === "/RAC")
    //   bgurl = `${MainServerUrl}/api/static/files/RAC_2023-8-1-19-8-42.jpg`;

    if (dept === "/Departments")
      // bgurl = `${MainServerUrl}/api/static/files/departments_photo_2023-9-25-19-18-7.jpg`;
      bgurl = `${MainServerUrl}/api/static/files/department_banner_2024-1-8-16-14-54.jpg`;

    if (dept === "/convocation2023")
      bgurl = `${MainServerUrl}/api/static/files/convocation2023_banner_2023-10-5-16-1-28.jpg`;
    if (dept === "/convocation2024")
      bgurl = `${MainServerUrl}/api/static/files/convocation2024_2024-10-28-11-45-9.jpg`;

    if (dept === "/FoundationDay")
      bgurl = `${MainServerUrl}/api/static/files/65th_Foundation_Day_v4_2023-10-6-10-42-43.jpg`;
    // if (dept === "/TT")
    //   bgurl = `${MainServerUrl}/api/static/files/TT_banner_2023-11-17-12-32-7.jpg`;
    if (dept === "/AB")
      bgurl = `${MainServerUrl}/api/static/files/Phd_brochure_2023-11-30-20-40-43.jpg`;
    // if (dept === "/AC")
    //   bgurl = `${MainServerUrl}/api/static/files/Academic_Calender_2023-11-22-11-45-51.jpg`;
    // if (dept === "/EB")
    //   bgurl = `${MainServerUrl}/api/static/files/Examination_banner_2023-11-24-17-53-28.jpg`;
    if (dept === "/careers")
      bgurl = `${MainServerUrl}/api/static/files/careers_banner_2023-12-21-19-20-46.jpg`;
    if (dept === "/technozion23")
      bgurl = `${MainServerUrl}/api/static/files/Technozion_23_2024-1-5-18-16-38.jpg`;
    // if (dept === "/RC2024")
    //   bgurl = `${MainServerUrl}/api/static/files/RSC2024_v3_2024-1-17-10-38-38.jpg`;
    // if (dept === "/RC2024")
    //   bgurl = `${MainServerUrl}/api/static/files/Research_Confluence_2024-1-23-16-5-33.jpg`;
    if (dept === "/RSC2024")
      bgurl = `${MainServerUrl}/api/static/files/RSC2024_2024-1-24-11-23-41.jpg`;
    if (dept === "/RSC2025")
      bgurl = `${MainServerUrl}/api/static/files/Confluence2025_2025-1-16-11-8-22.jpeg`;
    if (dept === "/newsletter")
      bgurl = `${MainServerUrl}/api/static/files/newsletters_2024-2-12-17-50-10.jpg`;
    if (dept === "/DeanMHW")
      bgurl = `${MainServerUrl}/api/static/files/Centre_for_Mental_Health_and_Wellness_2024-2-26-13-27-44.jpg`;
    if (dept === "/oldpandd")
      bgurl = `${MainServerUrl}/api/static/files/other_portals_and_doc_2024-3-1-17-33-37.jpg`;
    if (dept === "/KeyDocuments")
      bgurl = `${MainServerUrl}/api/static/files/key_documents_2024-2-26-16-12-23.jpg`;
    if (dept === "/Ioc")
      // bgurl = `${MainServerUrl}/api/static/files/ioc_2024-3-22-16-41-57.jpg`;
      bgurl = `${MainServerUrl}/api/static/files/Centre_for_Industry_Outreach_2024-3-28-12-26-52.jpg`;
    if (dept === "/sgr")
      bgurl = `${MainServerUrl}/api/static/files/Students_Grievance_Redressal_2024-3-30-11-7-49.jpg`;
    if (dept === "/IF")
      bgurl = `${MainServerUrl}/api/static/files/Institute_Facilities_2024-5-10-11-48-29.jpg`;
    if (dept === "/RDC")
      bgurl = `${MainServerUrl}/api/static/files/RDC_2024-5-21-17-57-20.jpg`;
      if (dept === "/Rc")
      bgurl = `${MainServerUrl}/api/static/files/Research_Centers_and_Facilities_2024-5-21-13-11-50.jpg`;



    return false;
  };

  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>
      {getbgURL(props.dept)}

      {/* <section className="section breadcrumb-classic context-dark">
        <div className="container">
          <h1 style={{fontWeight:'bolder'}}>{title}</h1>
          <div className="offset-top-10 offset-md-top-35">
            <ul className="list-inline list-inline-lg list-inline-dashed p">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Departments</a>
              </li>
              <li>{title}</li>
            </ul>
          </div>
        </div>
      </section> */}

      {/* <section className="section section-xxl text-center parallax-container" data-parallax-img={bgurl}>
        
        <div className="parallax-content">
          <div className="container context-dark">
            <h1 style={{fontWeight:'bolder'}} >{title}</h1>            
          </div>
        </div>
      </section> */}
      <section className="section context-dark">
        <div className="bg-vide" data-vide-bg={bgurl}>
          <div className="container section-100 section-lg-160">

            {/* Head title  start page  */}
            <h1 style={{ fontWeight: "bolder", fontSize: "4rem" }}></h1>

            {/* page titile page image with text closed */}
            {/* <h1 style={{ fontWeight: "bolder", fontSize: "4rem" }}>{title}</h1> */}
            {/* page titile page image with text closed */}

            {/* Header title page closed  */}
            {/* <h1 style={{ fontWeight: "bolder", fontSize: "4rem" }}>  </h1> */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PageHeader;
