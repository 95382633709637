import { useState } from "react";
import '../css/cseDeptStyle.css';

const CrifPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-Awards");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div class="dept-wrapper">
            <div class="container">
                <section class="py-4 header">
                    <header>
                        <img src="https://nitw.ac.in/api/static/files/10_2024-12-18-11-22-58.jpg" class="img-fluid" />
                    </header>
                    <div class="row">
                        <div class="col-md-3">
                            {/* Tabs nav */}
                            <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a class="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a class={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected={activeTab === 'v-pills-Awards' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Awards' ? '#c20315' : '#00FFFF' }}> <span>About</span> </a>

                                <a class={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected={activeTab === 'v-pills-Faculty' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Faculty' ? '#c20315' : '#00FFFF' }}>
                                    <span>People </span>
                                </a>



                                <a class={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected={activeTab === 'v-pills-visionandmission' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-visionandmission' ? '#c20315' : '#00FFFF' }}>
                                    <span>Requisition Forms</span>
                                </a>

                                <a class={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected={activeTab === 'v-pills-NotableAlumni' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-NotableAlumni' ? '#c20315' : '#00FFFF' }}>
                                    <span>Facilities</span>
                                </a>


                                <a class={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }}> <span>Upcoming Facilities</span>
                                </a>




                               


                                <a class={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected={activeTab === 'v-pills-Students' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Students' ? '#c20315' : '#00FFFF' }}>
                                    <span>Tariff </span>
                                </a>


                                <a href="https://nitw.ac.in/page/?url=crifevents" class={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected={activeTab === 'vv-pills-academics' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-academics' ? '#c20315' : '#00FFFF' }}>
                                    <span>Events</span>
                                </a>
                                <a href="https://nitw.ac.in/istemnitw" class={`nav-link ${activeTab === 'v-pills-slotbooking' ? 'active' : ''}`}
                                    id="v-pills-slotbooking-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-slotbooking')} role="tab"
                                    aria-controls="v-pills-aslotbooking" aria-selected={activeTab === 'vv-pills-slotbooking' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-slotbooking' ? '#c20315' : '#00FFFF' }}>
                                    <span>Slot Booking</span>
                                </a>


                                <a class={`nav-link ${activeTab === 'v-pills-E-Books' ? 'active' : ''}`} id="v-pills-labs-E-Books" data-toggle="pill" onClick={() => handleTabClick('v-pills-E-Books')} role="tab"
                                    aria-controls="v-pills-E-Books"
                                    aria-selected={activeTab === 'v-pills-E-Books' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-E-Books' ? '#c20315' : '#00FFFF' }}>

                                    <span>Contact Us</span>
                                </a>
                                
                                {/* <a class={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`} 
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics"aria-selected={activeTab === 'vv-pills-academics' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-academics' ? '#c20315' : '#00FFFF' }}>
                                    <span>Notices/Announcements</span>
                                </a> */}





                                {/*<a class="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                {/* <a class={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Remote Access Service</span>
                                </a>
                                <a class={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>General Rules</span> </a> */}


                                {/* <a class={`nav-link ${activeTab === 'v-pills-AYL' ? 'active' : ''}`} id="v-pills-labs-AYL" data-toggle="pill" onClick={() => handleTabClick('v-pills-AYL')} role="tab"
                                    aria-controls="v-pills-AYL" aria-selected="false">
                                    <span>Ask your Librarian</span>
                                </a> */}

                                {/* <a class={`nav-link ${activeTab === 'v-pills-FIS' ? 'active' : ''}`} id="v-pills-labs-FIS" data-toggle="pill" onClick={() => handleTabClick('v-pills-FIS')} role="tab"
                                    aria-controls="v-pills-FIS" aria-selected="false">
                                    <span>Faculty Information System</span>
                                </a> */}


                            </div>
                            <div class="head_of_dept">
                                <a href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi"
                                    target="_blank"> <img src="https://nitw.ac.in/api/static/files/1_2024-12-18-11-20-34.jpg" alt="" class="img-fluid" width="190" height="220" />
                                    <h6>  Prof. Venkata Srilakshmi P</h6>
                                </a>
                                <p class="justified-text">Head, CRIF <br />
                                    <a href="mailto:crif_head@nitw.ac.in"
                                        style={{ color: "#808080" }}>crif_head@nitw.ac.in</a><br /> +91 870 2462965
                                </p>
                            </div>
                        </div>



                        <div class="col-md-9">
                            {/* Tabs content */}
                            <div class="tab-content" id="v-pills-tabContent">
                                {/*<div class="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div class={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'}  p-2`} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">




                                    <ul>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_Anechoic_chamber_2024-9-26-16-21-36.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for Anechoic chamber</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_Spectrum_Analyzer_2024-9-26-16-23-27.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for Spectrum Analyzer</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_PCB_Prototype_Design_Facility_2024-9-26-16-24-6.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for PCB Prototype Design Facility</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_PCB_Fabrication_&_Etching_Facility_2024-9-26-16-24-40.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for PCB Fabrication & Etching Facility</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_Vector_Network_Analyzer_2024-9-26-16-25-26.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for Vector Network Analyzer</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_NMR_Spectrometer_2023-11-3-10-27-56.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for NMR Spectrometer </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_ESR_Spectrometer_2023-11-3-10-28-30.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for ESR Spectrometer </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_ICP-OES_2023-11-3-10-29-10.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for ICP-OES </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_Impedance_Analyzer_2023-11-3-10-29-52.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for Impedance Analyzer </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_LCHRMS_2023-11-3-10-30-33.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for LCHRMS </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_Universal_Testing_Machine_(UTM)_2023-11-3-10-31-33.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for Universal Testing Machine (UTM) </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_UV_Vis_NIR_Spectrometer_2023-11-3-10-32-23.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for UV Vis NIR Spectrometer </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_Vacuum_Arc_Re-melting_Furnace_(VARF)_2023-11-3-10-34-6.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for Vacuum Arc Re-melting Furnace (VARF) </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_DSC_2023-11-3-10-34-48.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for DSC </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_FURNACE_2023-11-3-10-35-48.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for FURNACE </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_TGDTA_2023-11-3-10-36-46.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for TGDTA </a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_Fluorescence_2023-11-3-10-37-34.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for Fluorescence</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/FTIR_User_Requisition_Form_2023-3-3-15-58-48.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for FTIR</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_CHNS_analyzer_2023-3-18-12-33-57.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for CHNS Analyzer</a></li>
                                        <li><a href="https://nitw.ac.in/api/static/files/User_Requisition_Form_for_3D_Surface_Profilometer_2024-9-17-16-58-52.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> User Requisition Form for 3D Surface Profilometer</a></li>


                                    </ul>

                                </div>



                                <div class={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade    p-2" id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 class="mb-4">Academic Programmes</h4>*/}
                                    {/*<div class="row">
  <div class="col-md-12">
    <h3 class="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div class="row">

                                        <div class="col-md-10 col-sm-12">
                                            <div class="card-4">
                                                <div class="card-detail">
                                                    <div class="head-bx">
                                                        {/* <h3 class="" align='center'> Notices/Announcements </h3> */}
                                                        <p style={{ textAlign: 'justify' }}>

                                                            {/* <a href="https://nitw.ac.in/api/static/files/Advisory_on_recent_covid_-19_variant_2023-12-20-14-46-52.pdf">
                                                                - Institute Health Centre: Advisory on recent covid -19 variant  </a> <br></br> */}

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>




                                <div class={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div class="row laboratory-facilities">
                                        <div class="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 class="" align='center'>Remote Access Service</h3>
                                            </header>

                                            <p class="justified-text">Central Library, NITW introduced a new service called "Remote Access Services - MyLOFT (My Library On Fingertips)" for the benefit of Research Scholars and Faculty. MyLOFT is a mobile App. Through MyLOFT research scholars and faculty can access e-resources subscribed by the NITW anywhere and anytime. Hence, all the P.G. students, Ph.D. students and faculty are requested to utilize the facility to enhance the quality Engineering Education and Research.
                                                <br></br>
                                                <br></br>
                                                <b> Remote Access Services Request Form: </b>
                                                <a href="https://forms.gle/3UxbXJ3q9MEqY3PCA">
                                                    https://forms.gle/3UxbXJ3q9MEqY3PCA </a></p>


                                        </div>

                                    </div>
                                </div>


                                <div class={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade   p-2`} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 class=" mb-4">Phd</h4> <a href="" target="_blank"> <span class="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i class="fa fa-link " aria-hidden="true"></i> </a>
                                </div>



                                <div class={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            style={{ backgroundColor: peopleTab === 'Faculty' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("Faculty") }}>Head</a>


                                        {/* <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty</a> */}


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff"
                                            style={{ backgroundColor: peopleTab === 'NonTeachingStaff' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Officers</a>


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars"
                                            style={{ backgroundColor: peopleTab === 'ResearchScholars' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("ResearchScholars") }}>
                                            Staff</a>


                                    </div>

                                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div class={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 class=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span class="highlight">F</span>aculty Profiles <i class="fa fa-external-link" aria-hidden="true"></i></a>*/}
                                            <section id="profile-info">

                                                <div class="col-md-12 profile-info-individual">
                                                    <div class="row">
                                                        <div class="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/1_2024-12-18-11-20-34.jpg" alt=""
                                                                class="img-fluid" /> </a> </div>
                                                        <div class="col-md-9">
                                                            <div class="card ">
                                                                <div class="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi"
                                                                        target="_blank">Prof. Venkata Srilakshmi P</a> </h5>
                                                                    <span>Head, CRIF</span>
                                                                    <p class="contact-info">
                                                                        {/* <i class="fa fa-phone"
                                                                            aria-hidden="true"></i> -<br /> */}
                                                                        <i class="fa fa-mobile fa-lg"
                                                                            aria-hidden="true"></i> +91 870 2462965 <br /> <i
                                                                                class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:crif_head@nitw.ac.in">crif_head@nitw.ac.in</a> </p>
                                                                    {/* <p class="justified-text">Modeling, Simulation and Optimization; Process Simulation using Aspen; Reaction Engineering; Thermodynamics; Energy management and CO2 Capture.</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>

                                        </div>


                                        <div class={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div class="row academic_group">



                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/2_2024-12-18-11-20-57.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0"> Dr. T. Krishna Sai </h6> <span> Principal Scientific Officer </span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i
                                                                        class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:tksai@nitw.ac.in">tksai@nitw.ac.in </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/3_2024-12-18-11-21-14.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Shri. V.Sudhakar</h6><span>Technical Officer</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> +91 870 2464275 <br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:sudhakar@nitw.ac.in">sudhakar@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/4_2024-12-18-11-21-29.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Shri. Harish Madupu</h6><span>Technical Officer</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> +91 870 2464276 <br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:harishmadupu@nitw.ac.in">harishmadupu@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/5_2024-12-18-11-21-42.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Shri. Donthamseeti Ravikumar</h6><span>Technical Officer</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> +91 870 2464276<br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:ravikumar_d@nitw.ac.in">ravikumar_d@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/6_2024-12-18-11-21-56.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Smt. GSR Sanjeevini</h6><span>Technical Officer</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> +91 870 2464276<br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gsrs@nitw.ac.in">gsrs@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">

                                            <div class="row phd-std-row">
                                                {/* <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/7_2024-12-18-11-22-11.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0"> Shri. Kasubojula Sharath </h6><span> Technical Assistant </span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i
                                                                        class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:kasubojulas@nitw.ac.in">kasubojulas@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/8_2024-12-18-11-22-27.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0"> Shri. Jakkani Naveen </h6><span> Technician </span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i
                                                                        class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:naveenjakkani@nitw.ac.in">naveenjakkani@nitw.ac.in </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/9_2024-12-18-11-22-42.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0"> Shri. Bhagadhi Koteshwer Rao </h6><span> Office Attendant </span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i
                                                                        class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:koteshwerrao1973@nitw.ac.in">koteshwerrao1973@nitw.ac.in </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>







                                {/*<div class="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 class=" mb-4">Non-Teaching Staff</h4><table class="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div class="row std-society">
                                        <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                            aria-labelledby="v-pills-Students-tab">
                                            <div class="row std-society">
                                                <a href="https://nitw.ac.in/api/static/files/Tariff_list_CRIF_2024-5-6-15-2-26.pdf" target="_blank" className="mb-3">Tariff <i class="fa fa-arrow-circle-down" aria-hidden="true"></i></a>

                                                {/* <h4>Payment to be made to:</h4>
                                                <p>Head of Account: <b>F590/-CRIF Dean R&C</b><br />
                                                    A/C No.: <b>52109375198</b><br />
                                                    IFSc : <b>SBIN0020149 (SBI NITW Branch)</b><br />
                                                    Name of the Beneficiary: <b>Director, NIT Warangal</b></p> */}


                                            </div>
                                        </div>
                                    </div>
                                </div>










                                <div class={`tab-panel ${activeTab === 'v-pills-FIS' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-FIS" role="tabpanel"
                                    aria-labelledby="v-pills-FIS-tab">
                                    <div class="row FIS">

                                        {/* <h4 align='center'>Faculty Information System</h4><br></br><br></br> */}
                                        <p class="justified-text">
                                            The Central Library has implemented a new “Faculty Information System” under the Indian Research Information Network System (IRINS) to showcase the academic and research activities of the institute faculty members and provide an opportunity to create a scholarly research network.</p>
                                        <br></br>
                                        <p class="justified-text">
                                            The NITW- Indian Research Information Network System (IRINS) will support the integration of the existing research management system such as HR system, course management, grant management system, institutional repository, Open and commercial citation databases, scholarly publishers, etc. This will be integrated with the academic identities such as ORCID ID, Scopus ID, Research ID, Microsoft Academic ID, and Google Scholar ID for ingesting the scholarly publication from various sources. This Faculty Information System can become the source for the NIRF, NAC and other international raking systems. The Faculty Information System can be accessed by everyone through the following link:
                                        </p>

                                        <p class="justified-text"><b>Website: </b><a href="https://nitw.irins.org/">https://nitw.irins.org/</a></p>



                                    </div>
                                </div>




                                <div class={`tab-panel ${activeTab === 'v-pills-E-Books' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-E-Books" role="tabpanel"
                                    aria-labelledby="v-pills-E-Books-tab">
                                    <div class="row E-Books">


                                        <div className="contact_details col-md-6">
                                            <h4>Contact Us</h4>
                                            <p>Mail ID: <a href="mailto:crif_head@nitw.ac.in"><b>crif_head@nitw.ac.in</b></a><br />
                                                Landline Phone No : <b>+91 870 2462966-2965</b></p>
                                        </div>

                                        <div className="postal_details col-md-6">
                                            <h4>Postal Address:</h4>
                                            <p>HEAD,<br />
                                                CENTRAL RESEARCH INSTRUMENTATION FACILITY,<br />
                                                NATIONAL INSTITUTE OF TECHNOLOGY WARANGAL<br />
                                                HANAMKONDA, TELANGANA-506004

                                            </p>
                                        </div>




                                    </div>


                                    <div className="row">
                                        <a href="https://www.nitw.ac.in/stuti/" target="_blank"><b>STUTI website <i class="fa fa-external-link" aria-hidden="true"></i></b></a>
                                        <a href="https://www.istem.gov.in/" target="_blank"><b>I-STEM Website <i class="fa fa-external-link" aria-hidden="true"></i></b></a>
                                    </div>
                                </div>
                                <div class={`tab-panel ${activeTab === 'v-pills-AYL' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-AYL" role="tabpanel"
                                    aria-labelledby="v-pills-AYL-tab">
                                    <div class="row AYL">
                                        <p class="justified-text">
                                            <img src="https://nitw.ac.in/api/static/files/Ask_a_Libraria_2022-7-22-10-26-13.png" alt="profile-image" class="profile" /><br></br>
                                            Due to the Covid-19 pandemic, the Central library of NIT Warangal was close down for the public. However, we do not want the students and faculty suffer from getting any information or help from the library. Hence, the central library has created an online, real time ‘virtual reference service’ facility in the library webpage. ‘Ask a Librarian’ is a free online web-based service through email.

                                        </p>
                                    </div>
                                </div>

                                <div class={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div class="row academic_group">



                                        <ul>
                                            <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> High-Resolution Transmission Electron Microscope</li>
                                            <li>Simultaneous Thermal Analysis (STA)</li>
                                            {/* <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Powder X-ray Diffractometer</li>
                                            <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Digital Microscope</li> */}

                                        </ul>


                                    </div>


                                </div>



                                <div class={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                    aria-labelledby="nav-AdjunctFaculty-tab">

                                    <div class="col-md-12 profile-info-individual">
                                        <div class="row">

                                            <div class="col-md-9">
                                                <div class="card">
                                                    <div class="d-flex">
                                                        <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Tata_Narasinga_Rao_2023-10-20-18-32-20.png" alt=""
                                                            class="" width="150" height="180" /> </div>
                                                        <div class="ml-3 w-100">
                                                            <h6 class="mb-0 mt-0"> Prof. Tata Narasinga Rao </h6>

                                                            <span>Director, International Advanced Research Centre for Powder Metallurgy & New Materials (ARCI), Hyderabad</span>
                                                            <div class=" mt-2">
                                                                <p class="contact-info">  <i
                                                                    class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:tata@arci.res.in">director@arci.res.in, tata@arci.res.in</a> </p>
                                                                <p class="justified-text">Nanotechnology, Materials science, Energy materials, Li ion Batteries</p>

                                                                <p class="justified-text"><b> Website: </b> <a href="https://www.arci.res.in/people-pages/tn-rao-cv.pdf">https://www.arci.res.in/people-pages/tn-rao-cv.pdf</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-md-9">
                                                <div class="card">
                                                    <div class="d-flex">
                                                        <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sharma_2023-10-20-18-32-39.png" alt=""
                                                            class="img-fluid" width="150" height="180" /> </div>
                                                        <div class="ml-3 w-100">
                                                            <h6 class="mb-0 mt-0"> Prof. Pisupati V Sharma </h6>

                                                            <span>Professor of Energy and Mineral Engineering, and Chemical Engineering, Director, Center for Critical Minerals,
                                                                The Pennsylvania State University, USA</span>
                                                            <div class=" mt-2">
                                                                <p class="contact-info">  <i
                                                                    class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sxp17@psu.edu">sxp17@psu.edu</a> </p>
                                                                <p class="justified-text">Chemical kinetics, mass, and heat transfer in combustion processes; CFD modeling of combustion and air pollution systems; oxy-fuel combustion; coal processing and mineral matter transformations in gasification; coal, biomass, and petroleum coke gasification and recovery of rare earth and critical elements from secondary sources and industrial wastes</p>

                                                                <p class="justified-text"><b>Website: </b><a href="https://www.eme.psu.edu/directory/sarma-v-pisupati">https://www.eme.psu.edu/directory/sarma-v-pisupati</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>






                                            <div class="col-md-9">
                                                <div class="card">
                                                    <div class="d-flex">
                                                        <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Ajay_K_Dalai_2023-10-20-18-32-55.png" alt=""
                                                            class="img-fluid" width="150" height="180" /> </div>
                                                        <div class="ml-3 w-100">
                                                            <h6 class="mb-0 mt-0"> Prof. Ajay K Dalai </h6>

                                                            <span>Distinguished Professor and Canada Research Chair in Bioenergy & Environmentally Friendly Chemical Processing, Department of Chemical and Biological Engineering
                                                                College of Engineering, University of Saskatchewan, SK Canada</span>
                                                            <div class=" mt-2">
                                                                <p class="contact-info">  <i
                                                                    class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ajay.dalai@usask.ca">ajay.dalai@usask.ca</a> </p>
                                                                <p class="justified-text">Nanocatalysis, chemical reaction engineering, bio and petro-fuels, renewable energy, hydroprocessing, gas-to-liquid and environmental technologies</p>

                                                                <p class="justified-text"><b>Website:</b><a href="https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php">https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">

                                    {/* <h3>Web OPAC </h3> */}
                                    <div class="row notable-alumni">

                                        <ul>
                                            <li><a href="https://nitw.ac.in/api/static/files/lchrms_2022-10-21-15-54-30.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Liquid Chromatography-High Resolution Mass Spectrometry (LC-HRMS)</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/nmr_2022-10-21-15-55-20.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> NMR Spectroscopy</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/esr_2022-10-21-15-56-15.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> X Band ESR Spectroscopy</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/icpoes_2022-10-21-15-56-39.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Inductively Coupled Plasma-Optical Emission spectroscopy (ICP-OES)</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/uv-vis_2022-10-21-15-57-13.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> UV-Vis-NIR Spectrophotometer</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/pl_2022-10-21-15-57-36.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Fluorescence Spectrophotometer</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/utm_2022-10-21-15-57-59.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Universal Testing Machine (UTM)</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/tgdta_2022-10-21-15-58-29.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Thermogravimetry/Differential Thermal Analysis (TG/DTA)</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/ANECHOIC_CHAMBER_2023-4-12-19-39-5.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Anechoic chamber</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/chns_2022-10-21-15-59-47.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> CHNS Analyzer</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/dsc_2022-10-21-16-0-12.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Differential Scanning Calorimeter (DSC)</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/arc_2022-10-21-16-0-49.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Vacuum Arc Melting</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/impedence_2022-10-21-16-1-21.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Impedance Analyzer</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/sem_2022-10-21-16-2-4.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Scanning Electron Microscope (SEM)</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/microscope_2022-10-21-16-5-0.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Trinocular Polarizing Microscope</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/vectornetwork_2022-10-22-12-27-46.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Two Port Vector Network Analyzer</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/xrd_2022-10-21-16-5-38.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> X- Ray Diffraction (XRD)</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/pcb_prototype_brochure_2023-4-12-19-23-51.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> PCB Prototype Machine</a></li>
                                            <li><i class="fa fa-check-circle-o" aria-hidden="true"></i>  Liquid Nitrogen Plant</li>
                                            <li><a href="https://nitw.ac.in/api/static/files/FTIR_2023-4-12-19-29-24.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Fourier-Transform Infrared Spectroscope</a></li>
                                            <li><a href="https://nitw.ac.in/api/static/files/spectrum_analyzer_2023-4-12-19-32-1.pdf" target="_blank"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Spectrum Analyzer</a></li>

                                        </ul>

                                    </div>
                                </div>



                                <div class={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">


                                    {/* <img src="https://nitw.ac.in/api/static/files/hor_all_2024-7-12-13-13-57.jpg" alt="Image 1"
                                        style={{ width: '100%', height: '350px' }}
                                    /> */}

                                    <h3>About CRIF</h3>
                                    <p>Central Research Instrumentation Facility (CRIF) is an integrated facility to provide high-end analytical services to academic and industrial research. The CRIF works with a motto to bring out the research output of scholars matching the international standards. The central facility caters to the department requirements under a single umbrella by fostering interdisciplinary research. Students from the different branches of science and engineering come here for research and experimentation, creating a vibrant cross-disciplinary atmosphere. The CRIF is committed to expanding to facilitate state-of-the-art laboratory services to all academia and industries located across the country. The facility is spread across in an area of 28000 sq. ft.</p>



                                </div>

                                <div class={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">

                                </div>

                                <div class={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div class="details">




                                        {/* <h5>General Rules</h5> */}
                                        <p class="justified-text">
                                            -Library is a place for study and research. Strict silence is to be observed.
                                            <br></br>
                                            -A user has to show identity card whenever he / she enters the Library.
                                            <br></br>
                                            -All the personal belongings should be deposited at the counter.
                                            <br></br>
                                            -The user has to pay Rs. 10/- towards loss of token of personal belongings counter.
                                            <br></br>
                                            -Users are requested to allow frisking of person at the Library exit gate while leaving the Library.
                                            <br></br>
                                            -Personal books, printed material, photocopy material are not allowed inside the Library.
                                            <br></br>
                                            -Outsiders are not allowed into the Library.
                                            <br></br>
                                            -Smoking is strictly prohibited.

                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        </>
    );
}

export default CrifPage;
