// Import necessary libraries
import React, { useState } from 'react';
import _ from 'lodash';
// import 'bootstrap/dist/css/bootstrap.min.css';

const PressreleaseData = [
    {
        title: "DRDO chief encourages NITW graduates to pursue lifelong learning",
        image: "https://nitw.ac.in/api/static/files/Convocation2024_2024-12-10-17-42-16.jpg",
        datetime: "12 Dec, 2024",
        description: "Manjima Karmakar receiving her degree from DRDO chairman Sudhir V. Kamat at the 22nd convocation of NITW on Saturday ",
        link: 'https://www.thehindu.com/news/national/telangana/drdo-chief-encourages-nitw-graduates-to-pursue-lifelong-learning/article68931119.ece',
        date: '12-12-2024'
    },
    {
        title: "Convocation-2024",
        image: "https://nitw.ac.in/api/static/files/Convocation2024_2024-12-10-17-42-16.jpg",
        datetime: "12 Dec, 2024",
        description: " ",
        link: 'https://www.nitw.ac.in/papernews2024dec1/',
        date: '12-12-2024'
    },
    {
        title: "NIT Warangal scores impressive placement rates amid global job freezes, highest package of ₹88 lakh per annum bagged ",
        image: "https://nitw.ac.in/api/static/files/placement_2024-12-20-19-16-36.jpg",
        datetime: "08 july, 2024",
        description: "Ravi Shah, a B.Tech student in ECE from NIT Warangal achieves the highest package of ₹88 lakh per annum (LPA)",
        link: "https://www.thehindu.com/news/national/telangana/nit-warangal-scores-impressive-placement-rates-amid-global-job-freezes-highest-package-of-88-lakh-pla-bagged/article68363100.ece",
        date: '12-12-2024'
    },
    {
        title: "Centre for Krishna River Basin studies inaugurated",
        image: "https://nitw.ac.in/api/static/files/kr_press_2025-1-1-11-56-12.jpg",
        datetime: "25 May, 2024",
        description: "The Centre for Krishna River Basin Studies was inaugurated by Prof Vinod Tare, Centre for Ganga Basin Management and Studies (cGanga), Emeritus Professor, IIT Kanpur, and National Institute of Technology (NIT) Warangal Director Prof Bidyadhar Subudhi. ",
        link: 'https://www.newindianexpress.com/states/telangana/2024/May/25/centre-for-krishna-river-basin-studies-inaugurated',
        date: '25-05-2024'
    },

    
    {
        title: "NIT Warangal unveils ‘Amma’ App to aid children with hearing impairment and speech delay",
        image: "https://nitw.ac.in/api/static/files/amma_2024-12-20-19-14-56.jpg",
        datetime: "20 May, 2024",
        description: "NITW students receive a letter from DEIC on Monday ",
        link: 'https://www.thehindu.com/news/national/telangana/nit-warangal-unveils-amma-app-to-aid-children-with-hearing-impairment-and-speech-delay/article68196965.ece',
        date: '12-12-2024'
    },

    
    {
        title: "E-office file system operational at NIT-Warangal ",
        image: "https://nitw.ac.in/api/static/files/e_office_2024-12-20-19-16-7.jpg",
        datetime: "15 April, 2024",
        description: "NIT-W director Bidyadhar Subudhi launching the e-office file system on the campus on Monday.",
        link: "https://www.thehindu.com/news/national/telangana/e-office-file-system-operational-at-nit-warangal/article68067623.ece",
        date: '12-12-2024'
    },
    // {
    //     title: "DRDO chief encourages NITW graduates to pursue lifelong learning",
    //     image: "https://nitw.ac.in/api/static/files/Convocation2024_2024-12-10-17-42-16.jpg",
    //     datetime: "12 Dec, 2024", 
    //     description: "Manjima Karmakar receiving her degree from DRDO chairman Sudhir V. Kamat at the 22nd convocation of NITW on Saturday ",
    //     link: "https://www.thehindu.com/news/national/telangana/drdo-chief-encourages-nitw-graduates-to-pursue-lifelong-learning/article68931119.ece" ,
    //    },
    // { title: "Three generations of IIT Madras Alumni highlight how the Institute shaped their Entrepreneurial Journey", image: "https://www.iitm.ac.in/sites/default/files/styles/img_m1/public/happenings/press_release/pressrelease16_12_2024_09_55.jpg?itok=8-cYoW68&__ncforminfo=eOOJsIJgv6kF9mM1hYbhdkCliIMIXcZfnNdM889CmwsEgrknccHMyYcZX3cdCN21b2tmFt97OdlElr51J8FVbZweBIEjYR85", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 4", image: "https://www.iitm.ac.in/sites/default/files/styles/img_m1/public/happenings/press_release/pressrelease16_12_2024_09_55.jpg?itok=8-cYoW68&__ncforminfo=eOOJsIJgv6kF9mM1hYbhdkCliIMIXcZfnNdM889CmwsEgrknccHMyYcZX3cdCN21b2tmFt97OdlElr51J8FVbZweBIEjYR85", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 5", image: "image5.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 6", image: "image6.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 7", image: "image7.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 8", image: "image8.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 1", image: "image1.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 2", image: "image2.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 3", image: "image3.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 4", image: "image4.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 5", image: "image5.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 6", image: "image6.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 7", image: "image7.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 8", image: "image8.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 1", image: "image1.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 2", image: "image2.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 3", image: "image3.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 4", image: "image4.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 5", image: "image5.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 6", image: "image6.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 7", image: "image7.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 8", image: "image8.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 1", image: "image1.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 2", image: "image2.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 3", image: "image3.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 4", image: "image4.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 5", image: "image5.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 6", image: "image6.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 7", image: "image7.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 8", image: "image8.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 1", image: "image1.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 2", image: "image2.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 3", image: "image3.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 4", image: "image4.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 5", image: "image5.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 6", image: "image6.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 7", image: "image7.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 8", image: "image8.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 1", image: "image1.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 2", image: "image2.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 3", image: "image3.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 4", image: "image4.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 5", image: "image5.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 6", image: "image6.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 7", image: "image7.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },
    // { title: "Release 8", image: "image8.jpg", datetime: "12.Dec-2024", description: "welcomejsfkdfsjdfsd kslfskd fdksflksdlkfksdlfk s lsdkflsdk flsdfkskfd fskldfl ", link: "" },


];

const Pressrelease = () => {
    //   const [currentPage, setCurrentPage] = useState(1);
    //   const itemsPerPage = 4;

    //   // Paginate logic
    //   const startIndex = (currentPage - 1) * itemsPerPage;
    //   const paginatedNewsletters = _.slice(NEWSPAPER, startIndex, startIndex + itemsPerPage);

    //   // Handle page change
    //   const handlePageChange = (page) => setCurrentPage(page);
    //   // Handle page change
    //   const handlePageChange = (page) => {
    //     if (page >= 1 && page <= totalPages) {
    //       setCurrentPage(page);
    //     }
    //   };
    // const [currentPage, setCurrentPage] = useState(1);
    //   const itemsPerPage = 4;
    //   const totalPages = Math.ceil(NEWSPAPER.length / itemsPerPage);

    //   // Paginate logic
    //   const startIndex = (currentPage - 1) * itemsPerPage;
    //   const paginatedNewsletters = _.slice(NEWSPAPER, startIndex, startIndex + itemsPerPage);

    //   // Handle page change
    //   const handlePageChange = (page) => {
    //     if (page >= 1 && page <= totalPages) {
    //       setCurrentPage(page);
    //     }
    //   };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const totalPages = Math.ceil(PressreleaseData.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Calculate page range
    const getPageRange = () => {
        const pages = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 1);

        for (let page = startPage; page <= endPage; page++) {
            pages.push(page);
        }
        return pages;
    };

    // Paginate current items
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedNewsletters = _.slice(PressreleaseData, startIndex, startIndex + itemsPerPage);


    return (
        <div className="container text-center">
            <h2 className="mt-4">Press Releases</h2>

            <div className="row mt-4">
                {paginatedNewsletters.map((newsletter, index) => (
                    <div className="col-sm-12 col-md-4 col-lg-4 mb-4" key={index}>

                        <div className="card profile-card">

                            <div className="card-body">
                                {/* <a href={newsletter.link} target="_self" rel="noreferrer" style={{ textDecoration: 'none' }}> */}
                                <h5 className="card-title">{newsletter.title}</h5>
                                {/* </a> */}
                                <a href={newsletter.link} target="_self" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                    <img src={newsletter.image} alt={newsletter.name} className="rounded-image img-fluid" />
                                </a>
                                <p className='card-text' style={{ textAlign: 'justify' }}>{newsletter.description}</p>
                                <p style={{ display: 'inline', marginLeft: '10px' }}>
                                    {newsletter.datetime}
                                </p> &nbsp;&nbsp;
                                <a
                                    href={newsletter.link}
                                    className="btn btn-primary"
                                    style={{ padding: '0', margin: '0', display: 'inline-block' }}
                                >
                                    See More
                                </a>

                                {/* <a href={newsletter.link} class="btn btn-primary" style={{ padding: '0', morgin: '0' }}>See More</a>
                                <p style={{ textAlign: ' left' }}>{newsletter.datetime}</p> */}
                            </div>
                            {/* <div class="card-footer"style={{textAlign:' left', backgroundColor:'#f5f5f5'}}>{newsletter.datetime}</div> */}
                        </div>

                    </div>
                ))}
            </div>
            {/* Pagination Controls */}
            <nav>
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(1)}>First</button>
                    </li>

                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                    </li>

                    {getPageRange().map((page) => (
                        <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={page}>
                            <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                        </li>
                    ))}

                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                    </li>

                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(totalPages)}>Last</button>
                    </li>
                </ul>
            </nav>

            {/* Pagination */}
            {/* <nav>
        <ul className="pagination justify-content-center">
          {_.range(1, Math.ceil(NEWSPAPER.length / itemsPerPage) + 1).map((page) => (
            <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={page}>
              <button className="page-link" onClick={() => handlePageChange(page)}>
                {page}
              </button>
            </li>
          ))}
        </ul>
      </nav> */}
            {/* <nav>
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
          </li>

          {_.range(1, totalPages + 1).map((page) => (
            <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={page}>
              <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
            </li>
          ))}

          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav> */}

        </div>
    );
};

export default Pressrelease;