export const StudentAccoladesData = [
    
        // {
    //     isactive: 'yes',
    //     title: ''
    // },
     {
        isactive: 'yes',
        title: 'Mr. Katroth Kalyan Singh, an MTech PED student of Prof A Kirubakaran EE, received the Best Paper Award at the IEEE ICPC2T Conference, which was held at NIT Raipur from January 20th to 22nd, 2025, for his paper titled "Single-Phase Five-Level Common Ground Transformerless Switched Capacitor Inverters for PV Applications with Double Gain."'
    },
    {
        isactive: 'yes',
        title: 'Mr Dinesh Bejjanki, a PhD student working under the supervision of Prof Sampath Kumar Puttapati (CH), has received the best oral presentation award under the theme Advanced Materials at the Indian Chemical Engineering Congress -2024 (IIChE-CHEMCON 2024) Organised by the Indian Institute of Chemical Engineers, Doaba Regional Centre, NIT Jalandhar, December 27-30, 2024.'
    },
    // {
    //     isactive: 'yes',
    //     title: 'Mr. Neeraj (22MMB0A65), from the Department of Metallurgical and Materials Engineering, won a gold medal in the National PML Boxing Championship held in Mumbai on December 29, 2024. '
    // },
    {
        isactive: 'yes',
        title: 'Ms. Kajal a PhD student working under supervision  Prof Chockalingam (BT) has received the best oral presentation award in the International Conference on Biotechnology, Medicine, and Cancer Therapeutics (ICBMCT-2024), jointly organized by the Department of Biotechnology, Sri Venkateswara University, Tirupati, and the Department of Hematology & Oncology, University of Alabama, USA. '
    },
        {
        isactive: 'yes',
        title: 'Ms. Husaina Freeganjwala, working under  supervision of Prof Shyam P (BT), has received the Best Poster Award at the prestigious International Conference on Biotechnology, Medicine, and Cancer Therapeutics held at Sri Venkateswara University, Tirupati, A.P., from 27th to 29th December 2024.'
    },
    {
        isactive: 'yes',
        title: 'Mr. Baburao Simma working under the supervision of Prof. Surya K Ghosh (PH). He received the Best Poster Award at the 68th DAE Solid State Physics Symposium, BARC, Mumbai, sponsored by the Board of Research in Nuclear Sciences, Department of Atomic Energy, Government of India.'
    },
    {
        isactive: 'yes',
        title: 'Prof Subhash Chandra Bose and Mr. Rajat Jain, his Ph.D. scholar, for receiving the financial support to attend the 49th International Conference and Exhibition on Advanced Ceramics and Composites (ICACC 2025), held in the United States from January 26 to January 31, 2025.". ANRF has provided financial support to attend the international conference to both of them.'
    },
    {
        isactive: 'yes',
        title: 'Miss. Satwika Das, Research Scholar (Supervisor, Prof.  Ashish Prabhu ), Department of Biotechnology, has received the Best Poster Award at ENVIRONMENT 2024, an International Conference on Environmental Challenges, Opportunities, and Sustainable Solutions, held at IIT Guwahati.'
    },
    {
        isactive: 'yes',
        title: 'Sri Gouranga Maharana, Department of Physics (Supervisor: Prof. D Paul Joseph) has been awarded the Best Poster Award. He received this award for their research work presented in the 35th Annual General Meeting of the Materials Research Society of India held in collaboration with the International Union for Materials Research Societies-International Conference in Asia, Indore, during December 3–6, 2024.'
    },
    {
        isactive: 'yes',
        title: 'Bushra Shaik (3rd BTech), Sabeela (1st MTech VLSI) and Aakash Chaturvedi (1st MTech VLSI) are selected for the Grand Challenge under CoE in IIoT Sensors Project  "Hardware development for C-MET Sensor" with subject to a condition to  work along with Xalten Systems Pvt Ltd with a budget of Rs. 2 lakhs.'
    },
    {
        isactive: 'yes',
        title: 'Buddepu Murali Chinna Venkata Ramana (3rd BTech) and Pasumurthi Vinayakanitesh (3rd BTech) innovative idea got selected for "The MYOSA Event - 2025", organised by IEEE Sensors Council. The MYOSA board (motherboard and 5 sensor boards) sponsored by the IEEE Sensors Council will be sent to the team. The cost of this set of boards is around $250.'
    },
     {
        isactive: 'yes',
        title: 'Ms. Yuvashree Jayavelu (PhD student, Dept. of Physics, Supervisor-Prof. D. Paul Joseph) has been honored with a Telangana State Level Award in the National Research Paper Writing Competition organized by Vision for Viksit Bharat (VIVIBHA)–2024.'
    },
    {
    
        isactive: 'yes',
        title: 'Madasu Pavan Kumar, a research scholar under the supervision of Prof Thyageshwar  in the Department of Biotechnology, has been awarded the prestigious International Travel Support Grant by the Asian Crystallography Association (ACA). This grant will support his participation in the 18th Conference of the Asian Crystallographic Association, to be held in Kuala Lumpur, Malaysia, from December 1-6, 2024.'
    },
    {
        isactive: 'yes',
        title: 'NIT Warangals Team Mechxhausters Wins 1st Place in Cost Presentation at SUPRA SAE INDIA 2024'
    },

    {
        isactive: 'yes',
        title: 'Ms. Gargi Singh, Research Scholar in the Department of  Chemistry under the guidance of Prof. V Rajeshumar, successfully presented her research findings in the international conference "23rd Lecture Conference-ORCHEM 2024," which took place from September 9–September 11, 2024, at the University of Regensburg, Germany.'
    },
    {
        isactive: 'yes',
        title: ' Ms. Nakka Anitha,  Ph. D. Research Scholar in the Physics Dept  under the guidance of  Prof T. Venkatappa Rao got Financial Assistance for participating in  "International Conference on Renewable Energy and Energy Conversion Devices (ICREED-24), USA (21 October, 2024 to 22 October, 2024)" under  Anusandhan National Research Foundation (ANRF) of SERB.'
    },
    {
        isactive: 'yes',
        title: 'Mr. Jitesh Pani, Ph. D. Inspire Fellow, Department of Physics Received the Best Poster Award  and Cash Prize in the International Forum in Plasma and Thin Film Technologies for Sustainable  Development Goals, Taiwan and Awarded Taiwan Experience Education Program to Pursue Research Work on Energy Storage'
    },
    {
        isactive: 'yes',
        title: 'Mr. Soumya Ranjan Pradhan, Ph.D. Research Scholar under the supervision of Dr. V. Jayalakshmi, Department of Physics, has been awarded a DBT International Travel Grant to give an oral presentation at the 29th International Liquid Crystal Conference (ILCC2024), July 21-26, 2024, Rio de Janeiro, Brazil.'
    },
    {
        isactive: 'yes',
        title: 'Mr. Jitesh Pani, Ph. D. Inspire Fellow, Department of Physics Received the Best Poster Award  and Cash Prize in the International Forum in Plasma and Thin Film Technologies for Sustainable  Development Goals, Taiwan and Awarded Taiwan Experience Education Program to Pursue Research Work on Energy Storage'
    },
    {
        isactive: 'yes',
        title: 'Mr. Nikhil Vangety, Ph.D. Research Scholar under Prof. Sourabh Roy, Department of Physics received a DST-SERB International Travel Support grant for participating in the SPIE Photonics Europe conference held from 7th April to 11th April 2024 in Strasbourg, France'
    },
    {
        isactive: 'yes',
        title: 'Miss. Nila B., Ph. D. Research Scholar in the Department of Mathematics Received DST-SERB International Travel Support and CSIR Foreign Travel Grant to Participate 33rd European Conference on Operational Research in Copenhagen, Denmar'
    },
    {
        isactive: 'yes',
        title: 'Best Poster Award to Ms. Anjaly Babu (Department of Physics) in the conference on Innovations in Materials Science and workshop on Characterization Techniques at Indian Institute of Technology Roorkee on 24-27th April 2024.'
    },
    {
        isactive: 'yes',
        title: 'Best Paper Award to Mr. Shekher Sheelam in ICAME 2024 conference, SRM University, Chennai on 20-22nd Mar 2024.'
    },
    {
        isactive: 'yes',
        title: 'Best Oral Presentation Award to Ms. A. Mahalakshmi in the ICMSDT 2024, Central University of Jammu, J & K'
    },
    {
        isactive: 'yes',
        title: 'Mr. B. Dinesh, Ph. D. Student under Dr. P. Sampath Kumar has been awarded the Best Research Presentation in TLS 2024 at IIT Delhi.'
    },
    {
        isactive: 'yes',
        title: 'Best Paper Award for PhD Student Nitish Gupta and D. Bhargavi, "An Analytical Study of the Impact of an Inclined Magnetic Field on Couette Flow with Jeffrey Fluid under Local Thermal Non-Equilibrium (LTNE) and Viscous Dissipation", paper presented at the "International Conference on Advancements in Materials, Manufacturing, and Automation (AMMA-2023)", held during April 7-8, 2023, organized by Amrita Vishwa Vidyapeetham, Tamil Nadu, India.'
    },

]