// import React from 'react';
// import _ from 'lodash';
// import PageHeader from '../components/PageHeader';
// // import { GitHub, LinkedIn } from '@mui/icons-material';
// import { DEPARTMENTS } from '../constants/StaticDepartmentData';

// export const StaticDepartmentPage = () => {
//     return (
//         <div className="page text-center font-change-applicable">
//             <PageHeader dept={'/Departments'}></PageHeader>
//             <div className="p-4 container">
//                 <center>
//                     <h3 style={{ fontWeight: 'bold', fontSize: "2rem" }}>Departments</h3>
//                     {/* <p>This website is developed by the following Students of our college.</p> */}
//                 </center>
//                 <div className="row">
//                     {_.map(DEPARTMENTS, (department, index) =>
//                         <div className="col-sm-12 col-md-6 col-lg-4 member">
//                             <a href={department.link} target="_self" rel="noreferrer">
//                                 <div className="card profile-card-4">
//                                     <div >
//                                         <img src={department.image} alt="profile-sample1" />
//                                     </div>
//                                     {/* <div className="profile-thumb-block">
//                                 <img src={department.image} alt={department.name} className="profile"/>
//                             </div> */}
//                                     <div style={{ marginTop: "-50px" }} className="card-content">
//                                         <h2  ><a href={department.link} target="_self" rel="noreferrer">{department.name}</a></h2>
//                                         {/* <span>Reg. No: {department.regNo}</span><br/> */}
//                                         {/* <big>{department.role}</big> */}

//                                     </div>
//                                 </div>
//                             </a>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     )
// }

// import React from 'react';
// import _ from 'lodash';
// import PageHeader from '../components/PageHeader';
// // import { GitHub, LinkedIn } from '@mui/icons-material';
// import { DEPARTMENTS } from '../constants/StaticDepartmentData';

// export const StaticDepartmentPage = () => {
//     return (
//         <div className="page text-center font-change-applicable">
//             {/* <PageHeader dept={'/Departments'}></PageHeader> */}
//             <section>
//                 <img src="images/nit_dept_banner.jpg" alt=""/>
//             </section>
//             <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
                
//                 <div className="row">
//                     {_.map(DEPARTMENTS, (department, index) =>
//                         <div className="col-sm-12 col-md-4 col-lg-4 member" style={{border:'1px'}}>
//                             {/* <a href={department.link} target="_self" rel="noreferrer"> */}
//                                 <div className="card profile-card-4" style={{height:"auto"}}>
//                                     <div>
//                                         <img src={department.image} alt="profile-sample1" style={{width:"320px",height:"200px"}}/>
//                                     </div>
                                   
//                                     <div style={{ marginTop: "2px",backgroundColor:"transparent" }} className="card-content">
//                                         <h2><a href={department.link} target="_self" rel="noreferrer">{department.name}</a></h2>
                                        
//                                     </div>
//                                 </div>
//                             {/* </a> */}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };
import React from 'react';
import _ from 'lodash';
import { DEPARTMENTS } from '../constants/StaticDepartmentData';
import '../css//StaticDepartmentPage.css'; // Import a CSS file for styling

export const StaticDepartmentPage = () => {
    return (
        <div className="page text-center font-change-applicable">
            <section>
                <img src="images/nit_dept_banner.jpg" alt="Department Banner" className="department-banner" />
            </section>
            <div className="container department-container">
                <div className="row">
                    {_.map(DEPARTMENTS, (department, index) => (
                        <div key={index} className="col-sm-12 col-md-6 col-lg-4 department-card">
                            <div className="card profile-card">
                                <div className="card-image-container">
                                    <img 
                                        src={department.image} 
                                        alt={department.name} 
                                        className="department-image" style={{width:"340px",height:"260px"}}
                                    />
                                </div>
                                <div className="card-content">
                                    <h3 className="department-title">                                        
                                            {department.name}                                       
                                    </h3><br/>
                                    <a href={department.link} type="button" className="deptviewmore" target="_self" rel="noreferrer">
                                            View More
                                        </a>
                                    
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

