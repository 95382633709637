import React from 'react';
// import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css';
// import {IstemJsonData} from '../constants/IstemData;
import { PicData, IstemJsonData } from '../constants/PicData';



const IstemPage = () => {
    return (<div class="container"><br /><br />
        <center><h4 style={{ textDecoration: "underline" }}>I-STEM @ NIT Warangal</h4></center><br />
        <center> <img src='https://nitw.ac.in/api/static/files/istem-link_2024-12-16-22-47-17.png' /></center>
        <button type="button" class="btn btn-primary"><a href='https://www.istem.gov.in/' target='_blank'> Click here to visit I-STEM portal</a></button><br />

        <button type="button" class="btn btn-primary"> <a href='https://istem.gov.in/whatsnew_detail/15' target='_blank'> Guidelines for booking through I-STEM</a></button><br />


        <button type="button" class="btn btn-primary"> <a href='https://nitw.ac.in/api/static/files/crif_guid_2024-12-23-12-10-23.pdf' target='_blank'> Guidelines for NITW Users</a></button><br />

        <button type="button" class="btn btn-primary"> <a href='https://www.youtube.com/watch?v=1GajJfBc7io' target='_blank'> Tutorial Video on How to book equipment through the ISTEM Portal (NIT Warangal Users).</a></button><br />

        <b> <u>Contact for user assistance</u><br/>
            Shri. D. Ravikumar<br />
            Nodal Officer_I-STEM_NITW<br />
            Technical Officer, CRIF, NIT Warangal<br />
            Email: nodalofficer_istem@nitw.ac.in</b><br/>
        <table className="table table-bordered table-responsive">
            <thead>

            </thead>
            <tbody>
                <tr>
                    <th>
                        S.No
                    </th>
                    <th>
                        Name of the Departement/ Centre
                    </th>
                    <th>
                        Equipment Name
                    </th>
                    <th>
                        Requisition Form
                    </th>
                    <th>
                        I-STEM Page Link
                    </th>
                </tr>
                {IstemJsonData.map((data, index) => (
                    <tr>
                        <td>
                            {index + 1}
                        </td>
                        <td>
                            {data.deptname}
                        </td>
                        <td>
                            {data.eqp_name}
                        </td>
                        <td>
                            <a className="btn btn-info" href={data.requisition_form_link} style={{ padding: 0, marginTop: 0, marginBottom: 0 }} target='_self'> Download</a>
                        </td>
                        <td>
                            <a className="btn btn-primary" href={data.istem_link} style={{ padding: 0, marginTop: 0, marginBottom: 0 }} target='_self'> ISTEM-Link</a>

                        </td>
                    </tr>
                ))}



            </tbody>
        </table>

        



    </div>
    )
}
export default IstemPage;