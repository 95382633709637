// import React, { useState } from "react";
// import { useEffect } from "react";
// // import "../css/fonts.css";
// import "../css/Styles.css";

// function Tabs() {
//   const [t1,setT1]=useState(true)
//   const [t2,setT2]=useState(false)
//   function myfunc(){
//     var l=document.getElementById("tabpanel__tab-2")
//     var x1=document.getElementById("tabpanel__panel-2")
//     var x2=document.getElementById("tabpanel__panel-1")
//     var tab1s=document.getElementById("tab1s")
//     var tab2s=document.getElementById("tab2s")
//     if(l.checked){
//       setT1(true);
//       setT2(false);
//       x1.style.display='block'
//       x2.style.display='none'
//       tab1s.style.background='#2b4675'
//       tab2s.style.background='rgb(128, 50, 0)'
//     }
//     else{
//       setT1(false);
//       setT2(true);
//       x2.style.display='block'
//       x1.style.display='none'
//       tab1s.style.background='rgb(128, 50, 0)'
//       tab2s.style.background='#2b4675'
//     }
//   }
//   return (
//     <>
//       <div className="container_tabs">
//         <div className="tabpanel">
          
//           <input
//             type="radio"
//             name="tab"
//             id="tabpanel__tab-2"
//             className="tabpanel__tab"
//             // checked="checked"
//             onClick={myfunc}
//             defaultChecked={true}
//           />
//           <label htmlFor="tabpanel__tab-2 p-1" id="tab1s" className="tabpanel__tablabel">
//             Vision
//           </label>

//           <input
//             type="radio"
//             name="tab"
//             id="tabpanel__tab-1"
//             className="tabpanel__tab"
//             onClick={myfunc}
//           />
//           <label htmlFor="tabpanel__tab-1" id="tab2s" className="tabpanel__tablabel">
//             Mission
//           </label>
//           <div className="tabpanel__panels">
//             <div className="tabpanel__panel" id="tabpanel__panel-1">
//               <div className="media">
//                 <div className="media__body font-change-applicable">
//                   <h2>Mission</h2>
//                   <p>
//                     Imparting total quality education to develop innovative,
//                     entrepreneurial and ethical future professionals fit for
//                     globally competitive environment.
//                     {/* <a href="https://nitw.ac.in/page/?url=/nitwMission/update">...read more</a> */}
//                     <a href="https://nitw.ac.in/vis">...read more</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="tabpanel__panel" id="tabpanel__panel-2">
//               <div className="media">
//                 <div className="media__body font-change-applicable">
//                   <h2>Vision</h2>
//                   <p>
//                     Towards a Global Knowledge Hub, striving continuously in
//                     pursuit of excellence in Education, Research,
//                     Entrepreneurship and Technological services to the society
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Tabs;
import React, { useState } from "react";

function Tabs() {
  const [activeTab, setActiveTab] = useState('tabpanel__tab-2'); // Default to Vision tab

  const handleTabClick = (event) => {
    setActiveTab(event.target.htmlFor);
  };

  return (
    <div className="container_tabs">
      <style>
        {`
          .tabpanel__tablabel {
            display: inline-block;
            padding: 10px 20px;
            cursor: pointer;
            background-color: lightblue; /* Default background */
            color: white;
            margin-right: 5px;
            transition: background-color 0.3s ease;
          }
          
          .tabpanel__tablabel:hover {
            background-color: #2b4675; /* Hover background */
          }
          
          .tabpanel__tablabel.selected {
            background-color: #2b4675; /* Selected tab background */
          }
        `}
      </style>
      <div className="tabpanel">
        <input
          type="radio"
          name="tab"
          id="tabpanel__tab-2"
          className="tabpanel__tab"
          checked={activeTab === 'tabpanel__tab-2'}
          onChange={() => {}}
        />
        <label
          htmlFor="tabpanel__tab-2"
          id="tab1s"
          className={`tabpanel__tablabel ${activeTab === 'tabpanel__tab-2' ? 'selected' : ''}`}
          onClick={handleTabClick}
        >
          Vision
        </label>

        <input
          type="radio"
          name="tab"
          id="tabpanel__tab-1"
          className="tabpanel__tab"
          checked={activeTab === 'tabpanel__tab-1'}
          onChange={() => {}}
        />
        <label
          htmlFor="tabpanel__tab-1"
          id="tab2s"
          className={`tabpanel__tablabel ${activeTab === 'tabpanel__tab-1' ? 'selected' : ''}`}
          onClick={handleTabClick}
        >
          Mission
        </label>

        <div className="tabpanel__panels">
          <div
            className="tabpanel__panel"
            id="tabpanel__panel-1"
            style={{ display: activeTab === 'tabpanel__tab-1' ? 'block' : 'none' }}
          >
            <div className="media">
              <div className="media__body font-change-applicable">
                <h2>Mission</h2>
                <p>
                  Imparting total quality education to develop innovative,
                  entrepreneurial and ethical future professionals fit for
                  globally competitive environment.
                  <a href="https://nitw.ac.in/vis">...read more</a>
                </p>
              </div>
            </div>
          </div>
          <div
            className="tabpanel__panel"
            id="tabpanel__panel-2"
            style={{ display: activeTab === 'tabpanel__tab-2' ? 'block' : 'none' }}
          >
            <div className="media">
              <div className="media__body font-change-applicable">
                <h2>Vision</h2>
                <p>
                  Towards a Global Knowledge Hub, striving continuously in
                  pursuit of excellence in Education, Research,
                  Entrepreneurship, and Technological services to the society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tabs;

