import { useState } from "react";
import '../css/cseDeptStyle.css';

const SOMDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/DMS_2024-2-17-10-2-21.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected="false"> <span>Awards and Honours</span> </a>
                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>


                            </div>
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/sm-sunitha"
                                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/sm-sunitha" alt="" className="img-fluid" width="190" height="220" />
                                <h6>Prof. G Sunitha</h6>
                            </a>
                                <p className="justified-text"> Head of the Department <br /> <a href="som_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>som_hod@nitw.ac.in</a> <br />9490165349 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">

                                        <p className="justified-text">
                                            It was in the year 2000, when the ever vibrant and kaleidoscopic Centre for Management Studies saw its establishment within the heart of the National Institute of Technology, Warangal. In the subsequent years, the prestigious department was renamed as the School of Management, which is now further christened to the Department of Management Studies (DoMS). It has always strived hard to create bunch of Techno-Managers every year. The unique concept of creating Techno-Managers was in itself as an USP for SoM, NITW. But now keeping in view of the changing requirements and the nature of Management discipline it has opened doors to all graduates aspiring to do MBA.                                         </p>
                                        <p className="justified-text">
                                            The Focus DoMS is on its premier 2-year, full time MBA Programme spanning four semesters with various streams of specialisation like HR, Marketing, Finance, Operations, Analytics, IT etc. The course is typically designed for all graduates taking up MBA, thus instilling both technical and managerial competencies in the students. Being an Institute of National importance, it admits students from all over the nation and thus a bunch of intellectual minds come together and are carefully nurtured here to make them grow into the most valuable assetsin the world.                                         </p>

                                        <p className="justified-text">
                                            This distinguished department provides a wide array of educational and research programs, as well as offering invaluable training, consultancy services and outreach initiatives in the diverse realms of Management. In 2009, the Department of Management Studies embarked on a new venture, introducing the Doctor of Philosophy program, which spans across various management disciplines. Since its inception, the PhD program offered by the Department of Management Studies at NIT Warangal has significantly enriched the academic landscape. This program equips its participants with a profound understanding of the methodological tools essential for advancing the existing body of knowledge in the realm of management. The rigorous nature of this doctoral endeavour not only paves the way for a rewarding academic career but also prepares individuals for impactful research roles beyond the academic sphere.
                                        </p>

                                        <p className="justified-text">
                                            Nestled within the expansive grounds of NIT Warangal, the Department of Management Studies enjoys access to the institute's cutting-edge infrastructure. This encompasses state-of-the-art facilities such as interactive classrooms, well-equipped seminar halls, a richly stocked library, a comprehensive sports complex, comfortable hostels, and more, all of which collectively contribute to an enriching academic experience.                                        </p>



                                    </div>
                                                                        {/* NUMBERING START*/}
                                                                        <div class="row nirf-ranking-counter text-center">
                         
                         <div className="col-md-1"></div>
                         
                          <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                              <div class="">
                                  <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>10</span></h2>
                                  
                                  <h6 class="mt-0"><b>Faculty</b></h6>
                              </div>
                          </div>
 
                          <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                              <div class="">
                                  <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>1</span></h2>
                                  <h6 class="mt-0"><b>Staff</b></h6>
                              </div>
                          </div>
 
                          <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                              <div class="">
                                  <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>22</span></h2>
                                  <h6 class="mt-0"><b>PhD Research Scholars</b></h6>
                              </div>
                          </div>
 
 
                          {/* <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                              <div class="">
                                  <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>53</span></h2>
                                  <h6 class="mt-0"><b>Undergraduate Students</b></h6>
                              </div>
                          </div> */}
 
 
                          <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                              <div class="">
                                  <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>108</span></h2>
                                  <h6 class="mt-0"><b>Postgraduate Students</b></h6>
                              </div>
                          </div>
 
 
 
                          <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                              <div class="">
                                  <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>27</span></h2>
                                  <h6 class="mt-0"><b>Publications</b></h6>
                              </div>
                          </div>
                        
                                     </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>
                                            <p className="  mb-4">To carve aspiring leaders through excellence in Education, Research, Innovation and Societal service.</p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                                                className="img-fluid" />
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">Imparting management education synchronized with the needs of industry. </p>
                                            <p className="  mb-2">Strengthening leadership qualities. </p>
                                            <p className="  mb-2"> Enhancing the analytical and problem-solving abilities to serve societal needs.  </p>

                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoard/ManagementStudies" >Notices/Announcements</a>
                                        </h4>
                                    </div>


                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">

                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph. D Program</h3>


                                                        <p className="justified-text">The areas available for pursuing doctoral program are Economics, Financial Management, Information Technology, Marketing Management, Operations Management,
                                                            Organizational Behaviour & Human Resource Management, Strategic Management  <br></br>

                                                        </p>
                                                        <p className="justified-text"> The research at Department of Management Studies, NIT Warangal expects the participants to think critically and reflect originally with high ethical standards. In doing so, the participants will not only meet the rigour of NIT Warangal but also the societal need for excellent researchers and academicians.
                                                        </p>

                                                        <p className="justified-text">The full-time participants are provided with scholarship and accommodation at the institute. For part-time participants, the curriculum does provide the required flexibility to balance their work and research.
                                                        </p>


                                                        {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}

                                                        {/* <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/08/29/master-of-business-administration.pdf"
                                                        target="_blank">Syllabus ( PDF 1) <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                    */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Masters in Business Administration (MBA)</h3>


                                                        <p className="justified-text">
                                                            The Masters in Business Administration (MBA) program offered by the Department of Management Studies. The eligibility criteria for admission into MBA program  is Any Bachelor’s degree with at least 60% marks of 6.5 CGPA on 10-point scale (55% marks or 6.0 CGPA on 10-point scale for SC/ST/PWD candidates) with a valid CAT/MAT/GMAT score.


                                                        </p>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div className="row laboratory-facilities">

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>

                                                <h3 className="">Analytics and Simulation lab</h3>
                                            </header>
                                            <div className="row">
                                                <div>
                                                    <div className="card item-card card-block">
                                                        <p>The analytics lab has 30 machines. The machines have access to Capitaline database, IBM SPSS, IBM AMOS, India business insight, GRETL, R and Python software. The analytics lab has an inverter and provides uninterrupted power-supply.</p>
                                                    </div>
                                                </div>
                                                <img src="https://nitw.ac.in/api/static/files/Analytics_Lab_2023-9-25-18-41-10.jpg" alt="" className="img-fluid mb-5" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Wi-Fi enabled Interactive AC Classrooms</h3>
                                            </header>
                                            <div className="row">
                                                <div>
                                                    <div className="card item-card card-block">
                                                        {/* <p>This lab is used for Optical system design, Basic Optics.</p> */}
                                                    </div>
                                                </div>
                                                <img src="https://nitw.ac.in/api/static/files/Wi-Fi_enabled_Interactive_AC_Classrooms_2023-9-25-18-46-59.jpg" alt="" className="img-fluid mb-5" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Group Discussion Hall & Department Library</h3>
                                            </header>
                                            <div className="row">
                                                <div>
                                                    <div className="card item-card card-block">
                                                    </div>
                                                </div>
                                                <img src="https://nitw.ac.in/api/static/files/Group_Discussion_Hall_2023-9-25-18-52-37.jpg" alt="" className="img-fluid mb-5" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Seminar Hall to facilitate corporate interactions</h3>
                                            </header>
                                            <div className="row">
                                                <div>
                                                    <div className="card item-card card-block">
                                                    </div>
                                                </div>
                                                <img src="https://nitw.ac.in/api/static/files/Seminar_Hall_2023-9-25-18-55-51.jpg" alt="" className="img-fluid mb-5" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                                        </div>

                                    </div>

                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor</a>

                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">

                                            <section id="profile-info">
                                            <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/sm-amandua"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/amandua_2025-1-2-10-21-28.jpg" alt=""
                                                            className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-amandua"
                                                                        target="_blank">Prof. Aman Dua</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9759134215<br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:amandua@nitw.ac.in ">amandua@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">Operations and Supply Chain Management, Sustainability Management, Business Analytics, ERP and Business Analytics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/sm-kfsudhakar"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Francis_Sudhakar_2024-2-21-16-30-25.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-kfsudhakar"
                                                                        target="_blank">Prof. Francis Sudhakar</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969516<br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kfsudhakar@nitw.ac.in ">kfsudhakar@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">Marketing Management, Services Marketing, Consumer Behavior, Product Management, Industrial Marketing, Advertising Management and General Management.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/sm-sltdevi"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Lakshmi_Tulasi_Devi_S_2024-2-21-16-32-15.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-sltdevi"
                                                                        target="_blank">Prof. Lakshmi Tulasi Devi S</a>
                                                                    </h5> <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9440545865 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto: sltdevi@nitw.ac.in   "> sltdevi@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text">Corporate Finance , Financial Econometrics, Financial Technology, Risk Management, Sustainable Finance, Green Finance & Women Entrepreneurship.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/sm-shafimk"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/shafimk_2024-12-17-17-21-10.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-shafimk"
                                                                        target="_blank">Prof. Muhammed Shafi MK</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7207447589 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto: shafimk@nitw.ac.in"> shafimk@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text">Investment Analysis, Financial Inclusion and Financial Management</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/sm-ramlal"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="https://nitw.ac.in/api/static/files/Prof._P_Ramlal_2024-2-21-16-31-16.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-ramlal"
                                                                        target="_blank">Prof. P. Ramlal</a> </h5>
                                                                    <span>Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9985150248 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ramlal@nitw.ac.in ">ramlal@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text"> Human Resource Management, Organizational Behaviour, Psychological Testing, Soft Skills, Corporate Training, Industrial Relations , Tribal Development, Disability Studies and CSR CD.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/sm-vrdevi"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Vangapandu_Rama_Devi_2024-2-21-16-33-57.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-vrdevi"
                                                                            target="_blank">Prof. Rama Devi V</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9000453743 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vrdevi@nitw.ac.in  ">vrdevi@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Organizational Behaviour; Human Resource Management; Performance Management; Employee Engagement; Work-Life Balance; Talent Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/sm-prcgopal"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Ramachandra_Gopal_P_2024-2-21-16-29-3.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-prcgopal"
                                                                            target="_blank">Prof. Ramachandra Gopal P</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i>9843716992<br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:prcgopal@nitw.ac.in   ">prcgopal@nitw.ac.in  </a> </p>
                                                                        <p className="justified-text"> Operations Management, Supply Chain Management, Quality Management, Project Management, Business Statistics, Sustainability.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/sm-sunitha"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Sunitha_G_2024-2-21-16-33-8.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-sunitha"
                                                                            target="_blank">Prof. G Sunitha</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849853561 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:sunitha@nitw.ac.in">sunitha@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">Forensic and Fraud Accounting, Behavioural Finance, Investment Management, Personal Financial Planning with special focus on Retirement Planning, Smart Learning Management Systems and Digital Learning, Women Entrepreneurship and Scalability challenges of Women led Enterprises.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/sm-rahult"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Rahul_Thangeda_2024-2-21-16-29-28.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/sm-rahult"
                                                                            target="_blank">Prof. T Rahul</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i>  8978264848 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto: rahult@nitw.ac.in  "> rahult@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">Consumer Behaviour; Marketing Research ; Marketing Analytics
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </section>




                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                            aria-labelledby="nav-AdjunctFaculty-tab">
                                        



                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">
                                                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                        <a href="" target="_blank">
                                                          
                                                                <img src="https://nitw.ac.in/api/static/files/Habeeb_Syed_2024-8-9-17-37-20.jpg" alt=""
                                                                    className="img-fluid"  height="180"  style={{border: "5px solid #edc512"}}/>
                                                             </a> 
                                                             </div>
                                                    <div className="col-md-9">
                                                        <div className="card ">
                                                            <div className="data">
                                                                <h5> <a href=""
                                                                    target="_blank">Prof. Habeeb Syed</a>
                                                                </h5>
                                                                <p className="contact-info"> <i className="fa fa-phone"
                                                                    aria-hidden="true"></i> 8978625821 <br /> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto: hsyed_adh@nitw.ac.in   "> hsyed_adh@nitw.ac.in  </a>
                                                                </p>
                                                                <p className="justified-text">Marketing and IT</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">
                                                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                        <a href="" target="_blank">
                                                          
                                                                <img src="https://nitw.ac.in/api/static/files/Boora_Raj_Kumar_2024-8-9-17-28-17.jpg" alt=""
                                                                    className="img-fluid"  height="180"  style={{border: "5px solid #edc512"}}/>
                                                             </a> 
                                                             </div>
                                                    <div className="col-md-9">
                                                        <div className="card ">
                                                            <div className="data">
                                                                <h5> <a href=""
                                                                    target="_blank">Prof. Boora Rajkumar</a>
                                                                </h5>
                                                                <p className="contact-info"> <i className="fa fa-phone"
                                                                    aria-hidden="true"></i>98855 64417 <br /> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto: rajkumarboora@gmail.com   "> rajkumarboora@gmail.com </a>
                                                                </p>
                                                                <p className="justified-text">Finance &amp; Economics</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">
                                                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                        <a href="" target="_blank">
                                                          
                                                                <img src="https://nitw.ac.in/api/static/files/mba1_2024-8-9-18-20-50.png" alt=""
                                                                    className="img-fluid"  height="180"  style={{border: "5px solid #edc512"}}/>
                                                             </a> 
                                                             </div>
                                                    <div className="col-md-9">
                                                        <div className="card ">
                                                            <div className="data">
                                                                <h5> <a href=""
                                                                    target="_blank">Prof. Abhijith P S</a>
                                                                </h5>
                                                                <p className="contact-info"> <i className="fa fa-phone"
                                                                    aria-hidden="true"></i>8921842855 <br /> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto: abhijithputhuval@gmail.com   "> abhijithputhuval@gmail.com</a>
                                                                </p>
                                                                <p className="justified-text">Finance</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mrs._Hussna_2024-2-21-16-34-39.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Mrs. Hussna</h6> <span>Senior Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969488 / 0870-2462851 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:hussna@nitw.ac.in">hussna@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">

                                            <div className="row phd-std-row">
                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Pakala_Yamini_2024-4-22-10-27-35.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : Learning & Development<b></b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Pakala Yamini</h6>
                                                        <span>Full-Time Research Scholar </span>
                                                        <p className="contact-info"> 9550537646
                                                            <br /><span>
                                                                <a href="mailto:yaminip@student.nitw.ac.in" >yaminip@student.nitw.ac.in </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kappala_Manjusha_2023-9-25-17-43-43.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Digital HR & Learning Motivations   </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kappala Manjusha</h6>
                                                        <span>Full-Time Research Scholar   </span>
                                                        <hr />

                                                        <p className="contact-info"> 8186996142    <br /><span>
                                                            <a href="mailto:manjusha@student.nitw.ac.in" >manjusha@student.nitw.ac.in </a></span> </p>

                                                    </div>
                                                </div>










                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Gopi_Rajendra_Kumar_2023-9-25-17-45-56.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Web-rooming & Show-rooming Behaviours  </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Gopi Rajendra Kumar</h6>
                                                        <span>Full-Time Research Scholar       </span>
                                                        <hr />

                                                        <p className="contact-info"> 9951805540
                                                            <br /> <span> <a href="mailto:rajendragopi@student.nitw.ac.in  " >rajendragopi@student.nitw.ac.in  </a> </span></p>

                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Karra_Aruna_2024-4-22-18-32-53.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Marketing Strategies for Handloom Industry   </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Karra Aruna</h6>
                                                        <span>Full-Time Research Scholar </span>
                                                        <hr />

                                                        <p className="contact-info"> 9701073937
                                                            <br /> <span><a href="mailto:arunarjun@student.nitw.ac.in" >arunarjun@student.nitw.ac.in</a></span>  </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ankush_Gupta_2023-9-25-17-47-35.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>HR Metrics  </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Ankush Gupta</h6>
                                                        <span>Part-Time Research Scholar </span>
                                                        <hr />

                                                        <p className="contact-info">9419216800
                                                            <br /> <span> <a href="mailto:ankushg@student.nitw.ac.in" >ankushg@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Naini_Shalini_Reddy_2024-4-22-18-34-23.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Green Marketing</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Naini Shalini Reddy</h6>
                                                        <span>Full-Time Research Scholar   </span>
                                                        <hr />

                                                        <p className="contact-info"> 7893540540
                                                            <br /> <span> <a href="mailto:shalinireddynaini@student.nitw.ac.in" >shalinireddynaini@student.nitw.ac.in </a> </span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Padamata_Karthik_2024-4-22-18-29-2.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>High-Performance Work Systems</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Padamata Karthik</h6>
                                                        <span>Full-Time Research Scholar    </span>
                                                        <hr />

                                                        <p className="contact-info"> 9949094577
                                                            <br />  <span><a href="mailto:padamata.karthik@student.nitw.ac.in" >padamata.karthik@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Dasari_Shanti_Ratnam_2024-4-22-18-23-8.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>HR Analytics </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Dasari Shanti Ratnam</h6>
                                                        <span>Full-Time Research Scholar    </span>
                                                        <hr />

                                                        <p className="contact-info"> 8639462315
                                                            <br /> <span> <a href="mailto:shanti.dasari@student.nitw.ac.in" >shanti.dasari@student.nitw.ac.in</a> </span></p>

                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/T.V.S._Vasudeva_Rao_2023-9-25-17-51-10.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Life Insurance Marketing & Penetration </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">T.V.S. Vasudeva Rao</h6>
                                                        <span>Part-Time Research Scholar    </span>
                                                        <hr />

                                                        <p className="contact-info">  9650287722
                                                            <br /> <span> <a href="mailto:tv701974@student.nitw.ac.in " >tv701974@student.nitw.ac.in  </a></span> </p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Pothuganti_Sanjeeva_Kumar_2024-4-22-18-27-41.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Digital Well-being  </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Pothuganti Sanjeeva Kumar</h6>
                                                        <span>Full-Time Research Scholar    </span>
                                                        <hr />

                                                        <p className="contact-info"> 7306628358
                                                            <br /> <span> <a href="mailto:ps720124@student.nitw.ac.in" >ps720124@student.nitw.ac.in    </a> </span></p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Katike_Uttam_2024-4-22-18-32-4.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Bottom of the Pyramid: Subsistence Marketplaces   </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Katike Uttam</h6>
                                                        <span>Full-Time Research Scholar    </span>
                                                        <hr />

                                                        <p className="contact-info"> 9515987815
                                                            <br /> <span><a href="mailto:ku720125@student.nitw.ac.in" >ku720125@student.nitw.ac.in  </a> </span> </p>

                                                    </div>
                                                </div>










                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Suraj_Kumar_2023-9-25-17-54-21.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Consumer Behaviour Towards e-commerce   </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Suraj Kumar</h6>
                                                        <span>Full-Time Research Scholar </span>
                                                        <hr />

                                                        <p className="contact-info">  9883616369
                                                            <br /> <span> <a href="mailto:sk720126@student.nitw.ac.in " >sk720126@student.nitw.ac.in     </a> </span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Shivam_Azad_2024-4-22-18-20-50.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Green Finance  </b>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Shivam Azad</h6>
                                                        <span>Full-Time Research Scholar      </span>
                                                        <hr />

                                                        <p className="contact-info">  7007755136
                                                            <br /> <span> <a href="mailto:sa712188@student.nitw.ac.in" >sa712188@student.nitw.ac.in   </a></span> </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Vakiti_Jayalakshmi_2024-4-22-18-26-48.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Financial Technology </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Vakiti Jayalakshmi</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9866126921
                                                            <br /> <span> <a href="mailto:jl21smrer03@student.nitw.ac.in " >jl21smrer03@student.nitw.ac.in    </a></span> </p>

                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/V._Ramanjaneyulu_2023-9-25-17-59-2.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Consumer Behaviour in Mutual Funds Purchase   </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">V. Ramanjaneyulu</h6>
                                                        <span>Part-Time Research Scholar   </span>
                                                        <hr />

                                                        <p className="contact-info">  9885199831
                                                            <br /> <span> <a href="mailto: rv712189@student.nitw.ac.in" > rv712189@student.nitw.ac.in </a> </span></p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Alok_Mohan_2023-9-25-17-57-14.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Artificial Intelligence in Strategic Management  </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Alok Mohan</h6>
                                                        <span>Part-Time Research Scholar  </span>
                                                        <hr />

                                                        <p className="contact-info">  77708 66670
                                                            <br /><span>  <a href="mailto:am21smrep01@student.nitw.ac.in" >am21smrep01@student.nitw.ac.in    </a></span>  </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Himanshu_Dabar_2024-4-22-18-33-38.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Financial Analytics  </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Himanshu Dabar</h6>
                                                        <span>Full-Time Research Scholar  </span>
                                                        <hr />

                                                        <p className="contact-info">  7568892615
                                                            <br /> <span>  <a href="mailto:hd22smr1r01@student.nitw.ac.in " >hd22smr1r01@student.nitw.ac.in    </a> </span> </p>

                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Gowri_Shankar_2023-9-25-18-2-34.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>De-Dollarisation and its Impact on the International Trade</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K. Gowri Shankar </h6>
                                                        <span>Part-Time Research Scholar </span>
                                                        <hr />

                                                        <p className="contact-info"> 9841007174
                                                            <br /> <span>  <a href="mailto:kg22smr1p02@student.nitw.ac.in " >kg22smr1p02@student.nitw.ac.in   </a> </span> </p>

                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/K.V.S._Abhishek_2023-9-25-17-59-42.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Digital Banking Technologies in Anti-Money Laundering   </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K.V.S. Abhishek</h6>
                                                        <span>Part-Time Research Scholar   </span>
                                                        <hr />

                                                        <p className="contact-info"> 9908643073
                                                            <br /> <span>  <a href="mailto:kv22smr1p01@student.nitw.ac.in" >kv22smr1p01@student.nitw.ac.in  </a></span>  </p>

                                                    </div>
                                                </div>








                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/K._Madhan_Kumar_2023-9-25-18-0-26.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Employee Well-Being    </b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K. Madhan Kumar</h6>
                                                        <span>Part-Time Research Scholar   </span>
                                                        <hr />

                                                        <p className="contact-info"> 9789715450
                                                            <br />  <span> <a href="mailto:mk22smr1p03@student.nitw.ac.in" >mk22smr1p03@student.nitw.ac.in   </a> </span> </p>

                                                    </div>
                                                </div>









                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Lankapalli_Libny_2024-4-22-18-29-42.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Gender Studies and Career Progression</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Lankapalli Libny</h6>
                                                        <span>Full-Time Research Scholar  </span>
                                                        <hr />

                                                        <p className="contact-info">  9381136515
                                                            <br /> <span>  <a href="mailto:ll23smr1r01@student.nitw.ac.in" >ll23smr1r01@student.nitw.ac.in   </a></span>  </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Shaistha_Tabassum_2024-4-22-18-25-25.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Artificial Intelligence in Financial Accounting</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Shaistha Tabassum</h6>
                                                        <span>Full-Time Research Scholar  </span>
                                                        <hr />

                                                        <p className="contact-info">  9676067963
                                                            <br /> <span>  <a href="mailto:st23smr1s01@student.nitw.ac.in" >st23smr1s01@student.nitw.ac.in </a> </span> </p>

                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Byram_Satish_2023-9-25-18-4-44.jpg" className="img-fluid image" alt="" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Industrial Relations</b>
                                                                    </p>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Byram Satish</h6>
                                                        <span>Part-Time Research Scholar  </span>
                                                        <hr />

                                                        <p className="contact-info">  7588691237
                                                            <br /> <span>  <a href="mailto:bs23smr1p01@student.nitw.ac.in" >bs23smr1p01@student.nitw.ac.in </a> </span> </p>

                                                    </div>
                                                </div>













                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">

                                        {/* <p className="justified-text"> 1. SPIE-Student chapter </p>
                                    <p className="justified-text"> 2. Physics association   </p>

                                    <p className="justified-text"> 3. OSA-Student chapter  </p> */}



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16410"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._P_Ramlal_2024-2-21-16-31-16.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16410"
                                                    target="_blank">
                                                    <h6>Prof. P. Ramlal</h6> <span> Faculty Co-ordinator<br></br> Abhiyan Club </span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9985150248 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="ramlal@nitw.ac.in "
                                                            style={{ color: "#808080" }}>ramlal@nitw.ac.in </a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16650"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Rahul_Thangeda_2024-2-21-16-29-28.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16650"
                                                    target="_blank">
                                                    <h6>Prof. T Rahul</h6> <span>  Faculty Advisor<br></br> Newsletters Team, Branding and Cyber Cell, and Social Outreach Team </span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i>8978264848<br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:rahult@nitw.ac.in"
                                                            style={{ color: "#808080" }}>rahult@nitw.ac.in </a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/sm-kfsudhakar"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Francis_Sudhakar_2024-2-21-16-30-25.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/sm-kfsudhakar"
                                                    target="_blank">
                                                    <h6>Prof. Francis Sudhakar</h6> <span>   Faculty Advisor<br></br> Alumni Relations </span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i>8332969516<br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:kfsudhakar@nitw.ac.in "
                                                            style={{ color: "#808080" }}>kfsudhakar@nitw.ac.in  </a> </p>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="-"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/som_student_2024-12-27-17-55-30.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="-"
                                                    target="_blank">
                                                    <h6>Mr. U R Gopi Krishnan</h6> <span>Student Co-ordinator<br></br> Abhiyan Club </span>
                                                    <hr />
                                                </a>
                                                    <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i>8137024005<br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:ur23smg1r41@student.nitw.ac"
                                                            style={{ color: "#808080" }}>ur23smg1r41@student.nitw.ac </a> </p>
                                                </div>
                                            </div>
                                        </div>






                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    <div className="row notable-alumni">



                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <img src="https://nitw.ac.in/api/static/files/Dr._Venkataiah_Chittipaka_2023-9-25-15-46-53.jpg" alt="" className="img-fluid" width="150" height="180"/>
                                                <div className="data">
                                                    <h6>Dr. Venkataiah Chittipaka</h6>

                                                     <p className="justified-text"><b>Batch :</b> 2002<br />
                                                        <b>Present Position :</b> Associate Professor (Operations Management), School of Management Studies, Indira Gandhi National Open University (IGNOU), India
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Penumatsa_Raghunadha_Raju_2023-9-25-15-47-49.jpg" alt="" className="img-fluid" width="150" height="180"/>
                                                <div className="data">
                                                    <h6>Penumatsa Raghunadha Raju</h6>


                                                    <p className="justified-text"><b>Batch :</b> 2002<br />
                                                        <b>Present Position :</b> Business Consultant Seamless Consultancy Services, India
                                                    </p>

                                                 

                                           
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Tizzle_Varghese_2023-9-25-15-48-31.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Tizzle Varghese</h6>
                                                    <p className="justified-text"><b>Batch :</b> 2002<br />
                                                        <b>Present Position :</b> UAE Segment Manager – Infrastructure, Henkel, UAE
                                                    </p>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Umapathi_Gajarla_2023-9-25-15-49-27.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Umapathi Gajarla</h6>
                                                    <p className="justified-text"><b>Batch :</b>2002<br />
                                                        <b>Present Position :</b>Assistant Vice President – Operations Analytics, Synchrony, India
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Rajesh_Vinna_2023-9-25-15-50-20.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Rajesh Vinna</h6>

                                                    <p className="justified-text"><b>Batch :</b>2002<br />


                                                        <b>Present Position :</b> Business Solutions Architect - Supply Chain and Digital Integration, Tronox, USA

                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Mary_Samuel_2023-9-25-15-51-11.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Mary Samuel</h6>

                                                    <p className="justified-text"><b>Batch :</b>2002<br />
                                                        <b>Present Position :</b> Cambridge Mentor; KPMG Certified Instructional Designer, Suchitra Academy, India

                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="https://nitw.ac.in/api/static/files/K._Gowri_Shankar_2023-9-25-15-45-6.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>K. Gowri Shankar</h6>

                                                    <p className="justified-text"><b>Batch: </b>2003<br />
                                                        <b>Present Position: </b>Group Director (Investments & Strategy), G & G Group of companies, Singapore
                                                    </p>

                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Koduri_Tarun_2023-9-25-15-52-4.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Koduri Tarun</h6>

                                                    <p className="justified-text"><b>Batch :</b>2003<br />
                                                        <b>Present Position :</b> Managing Director Deloitte Corporate Finance, India
                                                    </p>

                                                </div>
                                            </div>
                                        </div>


                                        {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="-" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Veena Vishwanath</h6>
                                                     <p className="justified-text"><b>Batch :</b>2004<br />
                                                        <b>Present Position :</b> Client Account HR Lead, Accenture, Sydney, Australia
                                                    </p>
                                                </div>
                                            </div>
                                        </div>  */}

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Rajesh_Pusarla_2023-9-25-15-54-35.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Rajesh Pusarla</h6>
                                                    <p className="justified-text"><b>Batch :</b>2005<br />
                                                        <b>Present Position :</b> Lead Business Analyst, HSBC, India
                                                    </p>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Vivekanandan_Nagarajan_2023-9-25-15-52-50.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Vivekanandan Nagarajan</h6>
                                                    <p className="justified-text"><b>Batch :</b>2006<br />
                                                        <b>Present Position :</b>Vice President Presales & Solutions, GAVS Technologies, India

                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Ram_Kumar_Nimmakayala_2023-9-25-15-53-33.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Ram Kumar Nimmakayala</h6>
                                                    <p className="justified-text"><b>Batch :</b>2006<br />
                                                        <b>Present Position :</b> AI Strategist, Data Engineering Manager / Data Product Manager, Western Governors University, USA
                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Krishna_Mohan_W.K._2023-9-25-15-55-16.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Krishna Mohan W.K.</h6>
                                                    <p className="justified-text"><b>Batch :</b>2006<br />
                                                        <b>Present Position :</b> Area Manager (SCV Cargo & Pickups), TATA Motors, India

                                                    </p>
                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Uma_Mahesh_Patil_2023-9-25-15-56-6.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Uma Mahesh Patil</h6>
                                                    <p className="justified-text"><b>Batch :</b>2006<br />
                                                        <b>Present Position :</b> Senior Manager - Cloud Solutions & Enterprise Architecture, Wipro Limited, Dubai, UAE

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        {/* 
                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="-" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>B. Durga Vijay Chand</h6>
                                                     <p className="justified-text"><b>Batch :</b>2006<br />
                                                        <b>Present Position :</b> Assistant General Manager - HR, NMDC Limited, India

                                                    </p>
                                                </div>
                                            </div>
                                        </div>  */}


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Mayank_Gaumat_2023-9-25-15-58-10.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Mayank Gaumat</h6>
                                                    <p className="justified-text"><b>Batch :</b>2006<br />
                                                        <b>Present Position :</b> Associate Director Sales Financial Services ME, Infosys, UAE

                                                    </p>
                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Dr._Aswini_Yadlapally_2023-9-25-15-59-0.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Dr. Aswini Yadlapally </h6>
                                                    <p className="justified-text"><b>Batch :</b>2008<br />
                                                        <b>Present Position :</b> Lecturer, ECDF RMIT University, Australia


                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/K.V._Sandeep_Chowdary_2023-9-25-15-59-50.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>K.V. Sandeep Chowdary  </h6>
                                                    <p className="justified-text"><b>Batch :</b>2008<br />
                                                        <b>Present Position :</b> Assistant Manager, Adani Ports, SEZ, Krishnapatnam Port, India



                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Rajkumar_Rapelly_2023-9-25-16-0-30.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Rajkumar Rapelly   </h6>
                                                    <p className="justified-text"><b>Batch :</b>2009<br />
                                                        <b>Present Position :</b> Manager (HRD) National Fertilizers Limited, India
                                                    </p>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Arvind_Kumar_2023-9-25-16-1-15.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Arvind Kumar   </h6>
                                                    <p className="justified-text"><b>Batch :</b>2010<br />
                                                        <b>Present Position :</b> CEO - Desai Brothers Limited, Ex-CEO - Dukes India.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>









                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Marketing
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Francis_Sudhakar_2024-2-21-16-30-25.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Francis Sudhakar </h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969516 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kfsudhakar@nitw.ac.in ">kfsudhakar@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Rahul_Thangeda_2024-2-21-16-29-28.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Rahul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8978264848 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rahult@nitw.ac.in ">rahult@nitw.ac.in </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Human Resource Management and Organizational Behaviour </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Vangapandu_Rama_Devi_2024-2-21-16-33-57.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rama Devi V</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9000453743 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vrdevi@nitw.ac.in ">vrdevi@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._P_Ramlal_2024-2-21-16-31-16.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Ramlal</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9985150248<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ramlal@nitw.ac.in "> ramlal@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Finance</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Lakshmi_Tulasi_Devi_S_2024-2-21-16-32-15.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Lakshmi Tulasi Devi S</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440545865<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sltdevi@nitw.ac.in  ">sltdevi@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Sunitha_G_2024-2-21-16-33-8.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. G Sunitha</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849853561 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: sunitha@nitw.ac.in  "> sunitha@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Operations & Analytics </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Ramachandra_Gopal_P_2024-2-21-16-29-3.jpg
" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ramachandra Gopal P</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9843716992 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:prcgopal@nitw.ac.in  ">prcgopal@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Rahul_Thangeda_2024-2-21-16-29-28.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Rahul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8978264848 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rahult@nitw.ac.in ">rahult@nitw.ac.in </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Entrepreneurship & Strategy  </h4>
                                            </header>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Rahul_Thangeda_2024-2-21-16-29-28.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Rahul</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8978264848 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rahult@nitw.ac.in ">rahult@nitw.ac.in </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Lakshmi_Tulasi_Devi_S_2024-2-21-16-32-15.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Lakshmi Tulasi Devi S</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440545865<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sltdevi@nitw.ac.in  ">sltdevi@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Vangapandu_Rama_Devi_2024-2-21-16-33-57.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rama Devi V</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9000453743 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vrdevi@nitw.ac.in ">vrdevi@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Francis_Sudhakar_2024-2-21-16-30-25.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Francis Sudhakar </h6>

                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969516 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kfsudhakar@nitw.ac.in ">kfsudhakar@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._P_Ramlal_2024-2-21-16-31-16.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Ramlal</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9985150248<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ramlal@nitw.ac.in "> ramlal@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>








                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Sunitha_G_2024-2-21-16-33-8.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. G Sunitha</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849853561 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: sunitha@nitw.ac.in  "> sunitha@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Women Studies   </h4>
                                            </header>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Vangapandu_Rama_Devi_2024-2-21-16-33-57.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rama Devi V</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9000453743 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vrdevi@nitw.ac.in ">vrdevi@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Sunitha_G_2024-2-21-16-33-8.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. G Sunitha</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849853561 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: sunitha@nitw.ac.in  "> sunitha@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Lakshmi_Tulasi_Devi_S_2024-2-21-16-32-15.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Lakshmi Tulasi Devi S</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440545865<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sltdevi@nitw.ac.in  ">sltdevi@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._P_Ramlal_2024-2-21-16-31-16.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Ramlal</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9985150248<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ramlal@nitw.ac.in "> ramlal@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Rahul_Thangeda_2024-2-21-16-29-28.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Rahul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8978264848 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rahult@nitw.ac.in ">rahult@nitw.ac.in </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Ramachandra_Gopal_P_2024-2-21-16-29-3.jpg
" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ramachandra Gopal P </h6>

                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9843716992 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:prcgopal@nitw.ac.in  ">prcgopal@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>













                                    </div>


                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Disability Studies    </h4>
                                            </header>
                                        </div>





                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._P_Ramlal_2024-2-21-16-31-16.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Ramlal</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9985150248<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ramlal@nitw.ac.in "> ramlal@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Lakshmi_Tulasi_Devi_S_2024-2-21-16-32-15.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Lakshmi Tulasi Devi S</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440545865<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sltdevi@nitw.ac.in  ">sltdevi@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Sunitha_G_2024-2-21-16-33-8.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. G Sunitha</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849853561 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: sunitha@nitw.ac.in  "> sunitha@nitw.ac.in  </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>





                                    </div>

                                </div>















                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        <h4>National Conference on New Management Paradigms in a Changing World: Innovations, Dynamics and Future Prospects (NMPCWIDF-2020)</h4>
                                        <span>31st January, 2020 to 1st February, 2020</span>
                                    </div>




                                    {/* <img src="https://nitw.ac.in/api/static/files/Photo_Gallery1_2023-9-18-17-17-12.jpg" alt="" className="img-fluid mb-5"/> 

                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery2_2023-9-18-17-25-58.jpg" alt="" className="img-fluid mb-5"/> 
                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery3_2023-9-18-17-26-20.jpg" alt="" className="img-fluid mb-5"/>
                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery4_2023-9-18-17-26-50.jpg" alt="" className="img-fluid mb-5"/>
                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery5_2023-9-18-17-27-10.jpg" alt="" className="img-fluid mb-5"/>


                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery6_2023-9-18-17-27-48.jpg" alt="" className="img-fluid mb-5"/>

                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery7_2023-9-18-17-28-18.jpg" alt="" className="img-fluid mb-5"/>

                                <img src="https://nitw.ac.in/api/static/files/Photo_Gallery8_2023-9-18-17-28-43.jpg" alt="" className="img-fluid mb-5"/> */}


                                    <img src="https://nitw.ac.in/api/static/files/NMPCWIDF-2020_2023-9-25-19-2-49.jpg" alt="" className="img-fluid mb-5" />


                                </div>



                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        <h4>CURA 2020 – February 14th and 15th 2020</h4>

                                        <img src="https://nitw.ac.in/api/static/files/CURA_2020_2023-9-25-19-4-24.jpg" alt="" className="img-fluid mb-5" />

                                    </div>



                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        <h4>Alumni Reunion - November 26th and 27th 2022</h4>

                                        <img src="https://nitw.ac.in/api/static/files/Alumni_Reunion_2023-9-25-19-7-21.jpg" alt="" className="img-fluid mb-5" />
                                    </div>



                                </div>




                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        <h4>Research Scholars Day – 28th April 2023</h4>
                                        <img src="https://nitw.ac.in/api/static/files/Research_Scholars_Day_2023-9-25-19-10-29.jpg" alt="" className="img-fluid mb-5" />

                                    </div>



                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        <h4>Teachers Day Celebrations – 2023</h4>
                                        <img src="https://nitw.ac.in/api/static/files/Teachers_Day_Celebrations_2023-9-25-19-13-44.jpg" alt="" className="img-fluid mb-5" />

                                    </div>



                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">
                                </div>




                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}


export default SOMDeptPage;