export const RcData = [

  // 2
  {
    name: 'Research Centres ',
    image: 'https://nitw.ac.in/api/static/files/AIML_2024-5-21-12-40-14.jpg',
    link: 'https://www.nitw.ac.in/cfr'
  },

  // 3 
  {
    name: 'Center for Innovation and Incubation ',
    image: 'https://nitw.ac.in/api/static/files/innovation_2024-5-21-12-43-32.jpg',
    link: 'https://www.nitw.ac.in/cii'
  },

  // 4

  {
    name: 'Central Research Instrumentation Facility ',
    image: 'https://nitw.ac.in/api/static/files/CRIF_2024-5-21-19-53-11.jpg',
    // link: 'https://www.nitw.ac.in/path/?dept=/nitwcentres'
    link: 'https://nitw.ac.in/crif'
  },


  {
    name: 'Digital Manufacturing and Automation ',
    image: 'https://nitw.ac.in/api/static/files/Digital_Manufacturing_and_Automation_2024-5-21-19-51-32.jpg',
    link: 'https://nitw.ac.in/siemens/'
  },

  {
    name: 'Vidya Shakti Center',
    image: 'https://nitw.ac.in/api/static/files/Vidya_Shakti_2024-5-29-12-20-47.jpg',
    link: 'https://www.nitw.ac.in/VidyaShakti'
  },

  // 5
  //   {
  //     name: 'Motor Transport ',
  //     image: 'https://nitw.ac.in/api/static/files/MT_SECTION_2024-5-10-16-21-58.jpg',
  //     link: 'https://nitw.ac.in/page/?url=/facilities/transport'
  //   },











  // Your newsletter data here
];
